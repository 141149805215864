import { useQuery } from '@tanstack/react-query';

import AuctionPreparationClient from '@api/AuctionPreparationClient';
import { authenticatedRequest, handleApiGetError } from '@api/helper';

export function useFilterSets() {
    const client = AuctionPreparationClient.instance.getApiClient();

    function queryFn() {
        return client.getFilterSets();
    }

    return useQuery(['filterSets'], () => authenticatedRequest(queryFn), {
        onError: error => {
            handleApiGetError(error);
        },
    });
}
