import {
    Preset,
    RfhAuctionPreparationClient,
} from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';

import config from '@config';
import { SELECTED_PRESET_KEY } from '@constants/storageKeys';
import { getAccessToken } from '@features/Auth/auth';
import i18n from '@root/i18n';
import { loadFromLocalStorage } from '@utils/LocalStorageUtils';
import { ApiClientWrapper } from './ApiClientWrapper';

export default class AuctionPreparationClient extends ApiClientWrapper<RfhAuctionPreparationClient> {
    private static _instance: AuctionPreparationClient;

    private constructor() {
        super();
    }

    public static get instance(): AuctionPreparationClient {
        if (!this._instance) {
            this._instance = new AuctionPreparationClient();
            this.instance.setSynthetic(config.useSynthetic);
        }

        this._instance.setLanguage();
        this._instance.setPresetId();
        this._instance.setToken();

        return this._instance;
    }

    public setSynthetic(useSynthetic: boolean): void {
        const client = this.getApiClient();
        client.setSynthetic(useSynthetic);
    }

    public setLanguage(): void {
        const client = this.getApiClient();
        client.setLanguage(i18n.language.substring(0, 2));
    }

    public setToken() {
        const client = this.getApiClient();
        getAccessToken().then(token => {
            if (token) {
                client.setToken(token);
            }
        });
    }

    public setPresetId(): void {
        const client = this.getApiClient();

        const preset: Preset = loadFromLocalStorage(SELECTED_PRESET_KEY);

        const presetId = preset?.presetId ?? '';

        client.setPresetId(presetId);
    }

    protected createServiceClient = (baseUrl: string): RfhAuctionPreparationClient =>
        new RfhAuctionPreparationClient(baseUrl);

    apiUrl = (): string => config.apiUrl;
}
