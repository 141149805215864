import { IGenericError } from './IGenericError';

export interface ISwaggerError extends IGenericError {
    isSwaggerException: boolean;
    response: string;
}

export function isSwaggerErrorType(error: unknown): error is ISwaggerError {
    return (
        !!error &&
        typeof error === 'object' &&
        'isSwaggerException' in error &&
        (error as ISwaggerError).isSwaggerException
    );
}
