import { SquareIconButton } from '@floriday/floriday-ui';
import { Grid } from '@mui/material';
import { ClockSupplyLine } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useTranslation } from 'react-i18next';

import OfflineMarkButtonMenu from '@features/Supply/Components/Marking/MarkButtonMenu/Components/MarkButtonMenu/MarkButtonMenu';
import MarkButtonMenu from '@features/Supply/Components/Marking/MarkButtonMenu/MarkButtonMenuContainer';
import { useNavigatorOnLine } from '@root/Hooks/useNavigatorOnLine';
import { FloridayPriceType, formatPrice, namespaces } from '@root/i18n';
import CartIcon from '@resources/Icons/CartIcon';
import FustIcon from '@resources/Icons/FustIcon';
import { usePresetContext } from '@store/Providers/PresetsProvider';
import { getExplorerLink } from '@utils/ExplorerUtils';
import { toPackages } from '@utils/QuantityFormatter';
import { useStyles } from './ScanRowStyles';

export interface IScanRowProps {
    readonly clockSupplyLine?: ClockSupplyLine;
    readonly characterIndex: string;
    readonly markingAccounts?: string[];
    readonly onOfflineMark?: (marked: boolean) => void;
    readonly offlineMarked?: boolean;
}

export default function ScanRow(props: IScanRowProps) {
    const { clockSupplyLine, markingAccounts, characterIndex, onOfflineMark = () => {}, offlineMarked = false } = props;
    const { classes } = useStyles();

    const { selectedPreset } = usePresetContext();

    const isOnline = useNavigatorOnLine();
    const { t } = useTranslation(namespaces.supply);

    const buyLink = getExplorerLink(
        clockSupplyLine?.clockPresalesSupplyLineId ?? '',
        selectedPreset?.organizationId ?? '',
    );

    function getPreSalePrice(): FloridayPriceType {
        return {
            currency: clockSupplyLine?.preSalePriceCurrency,
            value: clockSupplyLine?.preSalePriceValue,
        };
    }

    function getQuantity() {
        const piecesPerPackage = clockSupplyLine?.piecesPerPackage ?? 1;

        if (clockSupplyLine?.clockPresalesSupplyLineId) {
            const preSaleCurrentNumberOfPieces = clockSupplyLine.preSaleCurrentNumberOfPieces ?? 0;
            const currentNumberOfPieces = clockSupplyLine.currentNumberOfPieces ?? 0;

            return `${toPackages(preSaleCurrentNumberOfPieces, piecesPerPackage)}/${toPackages(currentNumberOfPieces, piecesPerPackage)}`;
        }

        return toPackages(clockSupplyLine?.currentNumberOfPieces ?? 0, piecesPerPackage);
    }

    return (
        <Grid container item xs={12} className={classes.row} data-testid='scanRow'>
            <Grid data-testid='characterIndex' item xs={1} className={classes.characterIndex}>
                {characterIndex}
            </Grid>
            <Grid item xs={3}>
                {isOnline && clockSupplyLine ? (
                    <MarkButtonMenu
                        supplyLineId={clockSupplyLine.id ?? ''}
                        digitalAuctionSupplyType={clockSupplyLine.digitalAuctionSupplyType}
                        users={markingAccounts ?? []}
                    />
                ) : (
                    <OfflineMarkButtonMenu onMark={onOfflineMark} marked={offlineMarked} />
                )}
            </Grid>
            <Grid container item xs={clockSupplyLine ? 6 : 8} className={classes.productDetails}>
                {clockSupplyLine ? (
                    <>
                        <Grid item xs={12} data-testid='product' className={classes.productName}>
                            {clockSupplyLine.productName}
                        </Grid>
                        <Grid item xs={6} data-testid='quantity' className={classes.productQuantity}>
                            <FustIcon />
                            {getQuantity()}
                        </Grid>
                        {clockSupplyLine.clockPresalesSupplyLineId && (
                            <Grid item xs={6} data-testid='preSalePrice'>
                                {formatPrice(getPreSalePrice())}
                            </Grid>
                        )}
                    </>
                ) : (
                    <Grid item xs={12} data-testid='product' className={classes.unavailableText}>
                        {t('scan.batchUnavailable')}
                    </Grid>
                )}
            </Grid>
            {isOnline && clockSupplyLine?.clockPresalesSupplyLineId && (
                <Grid item xs={2} className={classes.buyButtonContainer}>
                    <SquareIconButton
                        className={classes.buyButton}
                        data-testid='buyButtonScan'
                        onClick={e => {
                            e.stopPropagation();
                            window.open(buyLink, '_blank');
                        }}
                    >
                        <CartIcon />
                    </SquareIconButton>
                </Grid>
            )}
        </Grid>
    );
}
