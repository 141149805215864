import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    deleteDialog: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
        },
        width: '593px',
        height: '330px',
    },
    alertIcon: {
        width: '24px',
        height: '18px',
        fontSize: '21px',
        color: theme.palette.grey[600],
        marginRight: '12px',
    },
    closeButton: {
        float: 'right',
    },
    closeIcon: {
        color: theme.palette.grey[900],
        fontSize: '14px',
    },
}));
