import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';

export default function ClockSupplyLogoIconWithoutText(props: SvgIconProps) {
    return (
        <SvgIcon viewBox='0 0 175 156' {...props}>
            <path
                d='M170.006 122H171V121.006C171 98.4609 162.045 76.8393 146.104 60.8976C130.163 44.9559 108.543 36 86 36C63.4566 36 41.8365 44.9559 25.896 60.8976C9.95532 76.8393 1 98.4609 1 121.006V122H1.99415H45.0637H46.0579V121.006C46.0579 110.411 50.266 100.251 57.7566 92.7605C65.2472 85.2694 75.4067 81.0609 86 81.0609C96.5933 81.0609 106.753 85.2694 114.244 92.7605C121.734 100.251 125.942 110.411 125.942 121.006V122H126.936H170.006Z'
                fill='#898989'
                stroke='white'
                strokeWidth='2'
            />

            <path
                d='M89.8 1H82.2C81.5373 1 81 1.53726 81 2.2V25.8C81 26.4627 81.5373 27 82.2 27H89.8C90.4627 27 91 26.4627 91 25.8V2.2C91 1.53726 90.4627 1 89.8 1Z'
                fill='#3A3A3A'
                stroke='white'
                strokeWidth='2'
            />

            <path
                d='M127.816 23.1399L121.258 18.8066C120.708 18.443 119.969 18.5974 119.608 19.1515L110.759 32.7313C110.398 33.2854 110.552 34.0293 111.102 34.3929L117.659 38.7263C118.21 39.0899 118.948 38.9355 119.309 38.3814L128.158 24.8016C128.519 24.2475 128.366 23.5035 127.816 23.1399Z'
                fill='#3A3A3A'
                stroke='white'
                strokeWidth='2'
            />

            <path
                d='M111.956 10.1796L104.699 8.09332C104.066 7.9114 103.406 8.28054 103.226 8.91782L97.6104 28.7265C97.4297 29.3638 97.7963 30.0279 98.4291 30.2098L105.686 32.296C106.319 32.478 106.978 32.1088 107.159 31.4715L112.774 11.6629C112.955 11.0256 112.588 10.3615 111.956 10.1796Z'
                fill='#3A3A3A'
                stroke='white'
                strokeWidth='2'
            />

            <path
                d='M51.7366 17.2809L45.4925 21.4985C44.946 21.8677 44.8001 22.6132 45.1667 23.1636L54.2597 36.8155C54.6263 37.3659 55.3665 37.5128 55.9131 37.1436L62.1571 32.9259C62.7037 32.5568 62.8495 31.8113 62.4829 31.2609L53.39 17.609C53.0234 17.0586 52.2831 16.9117 51.7366 17.2809Z'
                fill='#3A3A3A'
                stroke='white'
                strokeWidth='2'
            />

            <path
                d='M67.935 8.09574L60.6889 10.22C60.057 10.4053 59.6939 11.0713 59.8779 11.7076L65.3179 30.5264C65.5019 31.1627 66.1633 31.5284 66.7951 31.3432L74.0412 29.2189C74.6731 29.0336 75.0362 28.3676 74.8523 27.7313L69.4122 8.9125C69.2282 8.27618 68.5669 7.9105 67.935 8.09574Z'
                fill='#3A3A3A'
                stroke='white'
                strokeWidth='2'
            />

            <path
                d='M2 121C2 102.538 8.06896 84.5926 19.2654 69.947C30.4617 55.3014 46.1597 44.7748 63.9238 40L75 81.5316C66.3442 83.8583 58.6952 88.9875 53.2396 96.1237C47.784 103.26 44.8269 112.004 44.8269 121H2Z'
                fill='#0DC988'
            />

            <path
                d='M106.846 40C124.852 44.6452 140.808 55.0851 152.231 69.6941C163.653 84.3031 169.9 102.261 170 120.771L126.778 121C126.729 111.979 123.685 103.227 118.118 96.1078C112.552 88.988 104.775 83.9 96 81.6362L106.846 40Z'
                fill='#371280'
            />
        </SvgIcon>
    );
}
