import { useEffect, useState } from 'react';
import { SpeechOIcon, LogoutIcon, InfoIcon, RefreshIcon, QuestionMarkIcon, RetryIcon } from '@floriday/floriday-icons';
import { UserMenu, UserMenuToggleButton, UserMenuSubHeader } from '@floriday/floriday-ui';
import { Badge, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { FileResponse } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';

import AuctionPreparationClient from '@api/AuctionPreparationClient';
import { authenticatedRequest, handleApiGetError } from '@api/helper';
import config from '@config';
import { IUser } from '@features/Auth/Contracts/IUser';
import { getUserAccount, signOut } from '@features/Auth/auth';
import { SupportedLocale, namespaces } from '@root/i18n';
import { useIntercomContext } from '@store/Providers/IntercomProvider';
import { usePresetContext } from '@store/Providers/PresetsProvider';
import LanguageSelector from './Components/LanguageSelector/LanguageSelector';
import PresetSection from './Components/PresetSection/PresetSection';
import { useStyles } from './UserMenuContainerStyles';

export default function UserMenuContainer() {
    const { classes } = useStyles();
    const [userAccount, setUserAccount] = useState<IUser>();
    const [languageSelectorOpen, setLanguageSelectorOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<Element | null>();
    const { t, i18n } = useTranslation(namespaces.general);
    const { showMessages } = useIntercom();
    const { unreadMessageCount } = useIntercomContext();
    const client = AuctionPreparationClient.instance.getApiClient();
    const queryClient = useQueryClient();
    const { selectedPreset } = usePresetContext();

    const { isFetching } = useQuery(['pricing-information'], () => authenticatedRequest(getPrincingInformation), {
        onError: error => {
            handleApiGetError(error);
        },
        onSuccess: (file: FileResponse) => {
            if (file) {
                const blob = new Blob([file.data], { type: 'text/csv;charset=utf-8;' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${t('userMenu.priceInfo')}.csv`;
                link.click();
            }
        },
        enabled: false,
    });

    function getPrincingInformation() {
        return client.getPricingInformation();
    }

    function handleHelpClick() {
        setAnchorEl(null);
        window.open(config.helpUrl[i18n.language as SupportedLocale]);
    }

    function handleDevelopmentsClick() {
        setAnchorEl(null);
        window.open(config.developmentsUrl[i18n.language as SupportedLocale]);
    }

    function handleIntercomClick() {
        setAnchorEl(null);
        showMessages();
    }

    function handlePriceInfoClick() {
        queryClient.fetchQuery(['pricing-information']);
    }

    function getOrganizationName() {
        return `${selectedPreset?.organizationName} (${selectedPreset?.organizationRelationNumber})`;
    }

    useEffect(() => {
        async function getUser() {
            const account = await getUserAccount();
            if (account) {
                setUserAccount(account);
            }
        }
        getUser();
    }, []);

    useEffect(() => {
        if (!isFetching) {
            setAnchorEl(null);
        }
    }, [isFetching]);

    return (
        <div data-testid='userMenu'>
            <UserMenuToggleButton
                userName={userAccount?.name ?? ''}
                userPhotoUrl={userAccount?.photoUrl ?? ''}
                organization={getOrganizationName()}
                onClick={e => setAnchorEl(e.currentTarget)}
            />
            <UserMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
                <UserMenuSubHeader label={t('userMenu.account')} />
                <MenuItem data-testid='language' onClick={() => setLanguageSelectorOpen(true)}>
                    <ListItemIcon>
                        <SpeechOIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={t('language.language')} />
                </MenuItem>
                <MenuItem onClick={handleHelpClick}>
                    <ListItemIcon>
                        <QuestionMarkIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={t('buttons.help')} />
                </MenuItem>
                <MenuItem onClick={handleDevelopmentsClick}>
                    <ListItemIcon>
                        <RetryIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={t('userMenu.developments')} />
                </MenuItem>
                {config.intercom.enabled && (
                    <MenuItem onClick={handleIntercomClick}>
                        <ListItemIcon>
                            <InfoIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Badge
                                    color='secondary'
                                    badgeContent={unreadMessageCount}
                                    classes={{ badge: classes.badge, root: classes.badgeRoot }}
                                >
                                    {t('userMenu.support')}
                                </Badge>
                            }
                        />
                    </MenuItem>
                )}
                {userAccount?.isAuctioneer && (
                    <MenuItem onClick={handlePriceInfoClick}>
                        <ListItemIcon>
                            {isFetching ? (
                                <RefreshIcon
                                    sx={{
                                        animation: 'spin 2s linear infinite',
                                        '@keyframes spin': {
                                            '0%': {
                                                transform: 'rotate(360deg)',
                                            },
                                            '100%': {
                                                transform: 'rotate(0deg)',
                                            },
                                        },
                                    }}
                                    fontSize='small'
                                />
                            ) : (
                                <InfoIcon fontSize='small' />
                            )}
                        </ListItemIcon>
                        <ListItemText primary={t('userMenu.priceInfo')} />
                    </MenuItem>
                )}
                <MenuItem onClick={signOut} divider>
                    <ListItemIcon>
                        <LogoutIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={t('buttons.signOut')} />
                </MenuItem>
                <PresetSection />
            </UserMenu>
            <LanguageSelector open={languageSelectorOpen} onClose={() => setLanguageSelectorOpen(false)} />
        </div>
    );
}
