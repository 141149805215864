import { ISupplyLineMarkingCommand } from '@features/Supply/Contracts/ISupplyLineMarkingCommand';

type AddMarkings = {
    type: 'ADD_MARKINGS';
    markings: ISupplyLineMarkingCommand[];
};

type AddMarking = {
    type: 'ADD_MARKING';
    marking: ISupplyLineMarkingCommand;
};

type UpdateMarking = {
    type: 'UPDATE_MARKING';
    marking: ISupplyLineMarkingCommand;
};

type DeleteMarking = {
    type: 'DELETE_MARKING';
    id: string;
};

type DeleteMarkings = {
    type: 'DELETE_MARKINGS';
    ids: string[];
};

type UpdateMarkings = {
    type: 'UPDATE_MARKINGS';
    markings: ISupplyLineMarkingCommand[];
    date: string;
};

export type MarkingsActions =
    | AddMarkings
    | AddMarking
    | UpdateMarking
    | DeleteMarking
    | DeleteMarkings
    | UpdateMarkings;

export function addMarkings(markings: ISupplyLineMarkingCommand[]): AddMarkings {
    return {
        type: 'ADD_MARKINGS',
        markings,
    };
}

export function addMarking(marking: ISupplyLineMarkingCommand): AddMarking {
    return {
        type: 'ADD_MARKING',
        marking,
    };
}

export function updateMarking(marking: ISupplyLineMarkingCommand): UpdateMarking {
    return {
        type: 'UPDATE_MARKING',
        marking,
    };
}

export function deleteMarking(id: string): DeleteMarking {
    return {
        type: 'DELETE_MARKING',
        id,
    };
}

export function deleteMarkings(ids: string[]): DeleteMarkings {
    return {
        type: 'DELETE_MARKINGS',
        ids,
    };
}

export function updateMarkings(markings: ISupplyLineMarkingCommand[], date: string): UpdateMarkings {
    return {
        type: 'UPDATE_MARKINGS',
        markings,
        date,
    };
}
