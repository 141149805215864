export abstract class ApiClientWrapper<T> {
    private serviceClient: T | undefined;
    private currentBaseUrl: string | undefined;
    protected abstract apiUrl(): string;
    protected abstract createServiceClient(baseUrl: string): T;

    public getApiClient(): T {
        const url = this.apiUrl();
        if (!this.serviceClient || this.currentBaseUrl !== url) {
            this.serviceClient = this.createServiceClient(url);
            this.currentBaseUrl = url;
        }

        return this.serviceClient;
    }
}
