import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { AUCTION_DATE_ROTATION_HOUR, DATE_FORMAT } from '@constants/auctionDateConstants';
import i18n from '@root/i18n';

dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export function auctionDateIsWithinRange(date: string) {
    const lowerLimit = dayjs().subtract(2, 'day');

    return dayjs(date, DATE_FORMAT).isAfter(lowerLimit, 'day');
}

export function getDefaultAuctionDate() {
    const currentDateTime = dayjs();
    const rotationMoment = dayjs.tz(`${dayjs().format(DATE_FORMAT)} ${AUCTION_DATE_ROTATION_HOUR}`, 'Europe/Amsterdam');

    return currentDateTime.isBefore(rotationMoment)
        ? currentDateTime.format(DATE_FORMAT)
        : currentDateTime.add(1, 'day').format(DATE_FORMAT);
}

export function formatMutationDate(inputDateString: string): string {
    const inputDate = new Date(inputDateString);

    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    };
    return new Intl.DateTimeFormat(i18n.language, options).format(inputDate);
}
