import { AlertFilledIcon, CloseIcon } from '@floriday/floriday-icons';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { namespaces } from '@root/i18n';
import { useBreakpointContext } from '@store/Providers/BreakpointProvider';
import { useStyles } from './DeleteFilterSetDialogStyles';

interface IProps {
    readonly showDialog: boolean;
    readonly filterSetName: string;
    readonly onCloseDialog: () => void;
    readonly onDelete: () => void;
    readonly onDialogClosed?: () => void;
}

export default function DeleteFilterSetDialog(props: IProps) {
    const { showDialog, filterSetName, onCloseDialog, onDelete, onDialogClosed } = props;
    const { isBreakpointSmall } = useBreakpointContext();
    const { classes } = useStyles();
    const { t } = useTranslation([namespaces.general, namespaces.supply]);

    return (
        <Dialog
            fullScreen={isBreakpointSmall}
            open={showDialog}
            onClose={onCloseDialog}
            classes={{ paper: classes.deleteDialog }}
            TransitionProps={{ onExited: onDialogClosed }}
        >
            <DialogTitle>
                <AlertFilledIcon className={classes.alertIcon} />
                <span>{t(`${namespaces.supply}:filterSetOverview.deleteDialogTitle`)}</span>
                <IconButton className={classes.closeButton} onClick={onCloseDialog} size='large'>
                    <CloseIcon className={classes.closeIcon} />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div data-testid='deleteFilterSetDialogContent'>
                    <p>
                        {t(`${namespaces.supply}:filterSetOverview.deleteDialogMessage`, {
                            interpolation: { escapeValue: false },
                            filterSetName,
                        })}
                    </p>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color='primary' size='medium' onClick={onCloseDialog}>
                    {t(`${namespaces.general}:buttons.cancel`)}
                </Button>
                <Button variant='contained' size='medium' color='primary' onClick={onDelete}>
                    {t(`${namespaces.general}:buttons.apply`)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
