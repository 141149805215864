import {
    FilterItem,
    FilterOption,
    SearchFilterItem,
    SupplyFilterType,
} from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';

import i18n, { namespaces } from '@root/i18n';

export function getMaingroupOptions(): FilterOption[] {
    return [
        { key: '1', code: '1', name: i18n.t(`${namespaces.supply}:clockSupplyFilter.cutFlowers`) },
        { key: '2', code: '2', name: i18n.t(`${namespaces.supply}:clockSupplyFilter.housePlants`) },
        { key: '3', code: '3', name: i18n.t(`${namespaces.supply}:clockSupplyFilter.gardenPlants`) },
    ];
}

export function getAvailabilityOptions(supplyFilterType: SupplyFilterType): FilterOption[] {
    if (supplyFilterType === SupplyFilterType.Marking) {
        return [
            {
                key: supplyFilterType.toLocaleLowerCase(),
                name: i18n.t(`${namespaces.supply}:clockSupplyFilter.markingOption`),
            },
        ];
    }
    if (supplyFilterType === SupplyFilterType.Presale) {
        return [
            {
                key: supplyFilterType.toLocaleLowerCase(),
                name: i18n.t(`${namespaces.supply}:clockSupplyFilter.presaleOption`),
            },
        ];
    }
    return [];
}

export function getAvailableOptions(filterType: SupplyFilterType, filterItems: FilterItem[]): FilterOption[] {
    return filterItems?.find(filterItem => filterItem.filterItemType === filterType)?.filterOptions ?? [];
}

export function getSelectedOptions(
    filterType: SupplyFilterType,
    currentSearchFilter: SearchFilterItem[],
    options: FilterOption[],
): FilterOption[] {
    const selectedKeys = currentSearchFilter?.find(y => y.filterItemType == filterType)?.filterOptionKeys ?? [];

    return options.filter(option => selectedKeys.some(key => key === option.key));
}

export function sortFilterChipListOptions(options: FilterOption[]) {
    return [...options].sort((a, b) => {
        if (a.order !== undefined && b.order !== undefined && a.order !== null && b.order !== null)
            return a.order - b.order;

        const filterOptionA = a.name.toUpperCase();
        const filterOptionB = b.name.toUpperCase();

        if (filterOptionA < filterOptionB) {
            return -1;
        }
        if (filterOptionA > filterOptionB) {
            return 1;
        }
        return 0;
    });
}
