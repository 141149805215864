import { Alert, AlertTitle } from '@mui/material';
import { FilterOption } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useTranslation } from 'react-i18next';

import { namespaces } from '@root/i18n';

interface IProps {
    readonly options: FilterOption[];
}

export function NonReferenceDataFilterOptions({ options }: IProps) {
    const { t } = useTranslation([namespaces.general, namespaces.supply]);
    if (options && options.length > 0) {
        return (
            <div data-testid='nonReferenceDataFilterOptions'>
                <Alert variant='outlined' severity='warning'>
                    <AlertTitle>{t(`${namespaces.general}:generic.warning`)}</AlertTitle>
                    <span>{t(`${namespaces.supply}:filterSetOverview.removedItemsWarning`)}:</span>
                    <ul>
                        {options?.map((filterOption: FilterOption) => (
                            <li key={filterOption.key} data-testid='nonReferenceDataFilterOptionsEntries'>
                                {filterOption.key}
                            </li>
                        ))}
                    </ul>
                </Alert>
            </div>
        );
    } else {
        return <></>;
    }
}
