import {
    FilterOption,
    SupplyFilterType,
} from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';

import FilterItem, { IProps as IFilterItemProps } from '../../../FilterItem/FilterItem';

interface IProps extends Omit<IFilterItemProps, 'item'> {
    readonly filterItem: FilterOption;
    readonly filterType: SupplyFilterType;
}

export default function VariableSizeListItemMobile(props: IProps) {
    const { filterItem, selected, showCount, showFilterOptionCode, onToggle, filterType } = props;

    return (
        <FilterItem
            item={filterItem}
            selected={selected}
            showCount={showCount}
            showFilterOptionCode={showFilterOptionCode}
            onToggle={onToggle}
            filterType={filterType}
        />
    );
}
