import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    filterItemContainer: {
        display: 'flex',
        // This is needed for Playwright. On scrolling this is set to 'none'.
        // And while it's 'none' you can't click on the element. We added 'auto' on this container because this seems to resolve the issue
        // Issue encountered in 'Selecting items in dialog should trigger search'
        pointerEvents: 'auto',
    },
    filterItem: {
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        '& label': {
            paddingRight: theme.spacing(0.5),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    },
    filterItemCount: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: '16px',
    },
}));
