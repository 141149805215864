import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    container: {
        width: '100%',
        fontSize: '14px',
        [theme.breakpoints.up('sm')]: {
            width: '50%',
        },
    },
    typography: {
        marginLeft: theme.spacing(0),
    },
    header: {
        fontSize: '20px',
        fontWeight: 800,
    },
    label: {
        fontWeight: 700,
    },
    unavailable: {
        fontStyle: 'italic',
    },
}));
