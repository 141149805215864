import { useEffect, useState } from 'react';

function getOnLineStatus() {
    return typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean' ? navigator.onLine : true;
}

export function useNavigatorOnLine() {
    const [status, setStatus] = useState(getOnLineStatus());

    function setOnline() {
        setStatus(true);
    }
    function setOffline() {
        setStatus(false);
    }

    useEffect(() => {
        window.addEventListener('online', setOnline);
        window.addEventListener('offline', setOffline);

        return () => {
            window.removeEventListener('online', setOnline);
            window.removeEventListener('offline', setOffline);
        };
    }, []);

    return status;
}
