import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<void, 'optionsContainer'>()((theme, _params, classes) => ({
    addButtonContainer: {
        margin: '24px 0',
        display: 'flex',
        justifyContent: 'end',
    },
    addButton: {
        backgroundColor: 'white',
    },
    filterSetRow: {
        maxHeight: '60px',
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        '&:hover': {
            backgroundColor: theme.palette.grey[50],
        },
        [`&:hover .${classes.optionsContainer}`]: {
            visibility: 'visible',
        },
    },
    nameCell: {
        cursor: 'pointer',
    },
    optionsContainer: {
        visibility: 'hidden',
        display: 'flex',
        justifyContent: 'end',
        gap: '8px',
    },
    checkboxContainer: {
        width: '48px',
        height: '48px',
        color: theme.palette.grey[600],
        padding: '7px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    checkboxIcon: {
        width: '16px',
        height: '16px',
    },
    iconFontSize: {
        fontSize: '16px',
    },
    markingCellWidth: {
        width: 200,
    },
    buttonCellWidth: {
        width: 100,
    },
    tableRow: {
        height: '60px',
    },
    tableBody: {
        '& td': {
            padding: '6px 16px',
        },
        '&:last-child': {
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
        },
    },
    deleteIconContainer: {
        width: '48px',
        height: '48px',
        color: theme.palette.grey[600],
        display: 'flex',
        justifyContent: 'center',
    },
    deleteIcon: {
        width: '14px',
        height: '15px',
    },
    editIcon: {
        width: '5px',
        height: '18px',
    },
    contextMenuContainer: {
        width: '48px',
        height: '48px',
        color: theme.palette.grey[600],
        '& svg': {
            height: '18px',
        },
    },
    paper: {
        marginBottom: '24px',
    },
}));
