import { ReactNode, createContext, useContext, useMemo, useState } from 'react';

interface IProps {
    readonly children?: ReactNode;
}

interface IScanClaimContext {
    hasPaaScanClaim: boolean;
    setHasPaaScanClaim: (value: boolean) => void;
}

const initialContext: IScanClaimContext = {
    hasPaaScanClaim: false,
    setHasPaaScanClaim: () => undefined,
};

const ScanClaimContext = createContext(initialContext);

export function ScanClaimProvider(props: IProps) {
    const { children } = props;
    const [hasPaaScanClaim, setHasPaaScanClaim] = useState(false);

    const value = useMemo<IScanClaimContext>(
        () => ({
            hasPaaScanClaim,
            setHasPaaScanClaim,
        }),
        [hasPaaScanClaim],
    );

    return <ScanClaimContext.Provider value={value}>{children}</ScanClaimContext.Provider>;
}

export function useScanClaimContext(): IScanClaimContext {
    return useContext(ScanClaimContext);
}
