import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    expansionPanelSummary: {
        minHeight: '60px !important',
        '&.Mui-expanded': {
            backgroundColor: theme.palette.grey[50],
        },

        '&:hover': {
            backgroundColor: theme.palette.grey[50],
        },

        '&.MuiAccordionSummary-content': {
            gap: theme.spacing(1),
            margin: '18px 0',
        },
    },
    filterItemName: {
        fontWeight: 'bold',
        flex: '1',
    },
    removeFilterValues: {
        cursor: 'pointer',
    },
    filterValuesCount: {
        alignSelf: 'flex-end',
        display: 'flex',
        justifyContent: 'center',
        minWidth: '48px',
    },
    detailsContainer: {
        flexDirection: 'column',
        padding: '16px 16px 24px',
    },
    accordionIcon: {
        fontSize: '16px',
    },
    accordion: {
        '&:first-of-type, &:first-of-type .MuiAccordionSummary-root': {
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
        },
        '&:last-child, &:last-child&:not(.Mui-expanded) .MuiAccordionSummary-root': {
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
        },
        '&:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
        },
        '&.Mui-expanded': {
            margin: 0,
        },
        '&:before': {
            display: 'none',
        },
    },
    text: {
        fontWeight: 'bold',
        paddingBottom: theme.spacing(2),
    },
}));
