import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
    badge: {
        fontSize: 9,
        height: 14,
        top: '50%',
        right: -16,
    },
    badgeRoot: {
        verticalAlign: 'unset',
    },
}));
