import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import { useStyles } from './CharacterButtonToolbarStyles';

interface IProps {
    readonly characters: { key: string; index: number }[];
    readonly onCharacterSelect: (index: number) => void;
}

export function CharacterButtonToolbar(props: IProps) {
    const { characters, onCharacterSelect } = props;
    const { classes } = useStyles();

    return (
        <ToggleButtonGroup className={classes.toggleButtonGroup} data-testid='characterButtonToolbar'>
            {characters.map(character => (
                <ToggleButton
                    className={classes.toggleButton}
                    data-testid='letter'
                    value={character.key}
                    onClick={() => onCharacterSelect(character.index)}
                    key={character.key}
                >
                    {character.key}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
}
