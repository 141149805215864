import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    alphabetContainer: {
        display: 'flex',
        alignItems: 'start',
        gap: theme.spacing(1),
    },
    text: {
        fontWeight: 'bold',
    },
}));
