import { ClockSupplySearchCommand } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useQueries } from '@tanstack/react-query';

import AuctionPreparationClient from '@api/AuctionPreparationClient';
import { authenticatedRequest, handleApiGetError } from '@api/helper';

interface IDataResult {
    readonly isLoading: boolean;
    readonly data: {
        [barcode: string]: string[];
    };
}

export function useSupplyLineIds(
    enabled: boolean,
    barcodes: string[],
    searchCommand: ClockSupplySearchCommand,
): IDataResult {
    const client = AuctionPreparationClient.instance.getApiClient();

    function queryFn(barcode: string) {
        return client.search({ ...searchCommand, query: barcode });
    }

    const results = useQueries({
        queries: enabled
            ? barcodes.map(barcode => ({
                  queryKey: ['supplyLines', barcode],
                  queryFn: () => authenticatedRequest(() => queryFn(barcode)),
                  onError: (error: Error) => {
                      handleApiGetError(error);
                  },
                  cacheTime: 0,
                  staleTime: Infinity,
              }))
            : [],
    });

    return {
        isLoading: results.some(result => result.isLoading),
        data: results.reduce(
            (acc, cur, curIndex) => ({
                ...acc,
                [barcodes[curIndex]]: cur.data?.results.map(supplyLine => supplyLine.id),
            }),
            {},
        ),
    };
}
