import { FlowerIcon, PlantIcon, PottedPlantIcon } from '@floriday/floriday-icons';
import { SvgIconProps } from '@mui/material';

import { MainGroupType } from '@contracts/MainGroupType';
import { useStyles } from './MainGroupIconStyle';

interface IProps extends SvgIconProps {
    readonly code: string;
}

export default function MainGroupIcon(props: IProps) {
    const { code, ...iconProps } = props;
    const { classes } = useStyles();
    return (
        <>
            {code.startsWith(MainGroupType.CutFlower) && (
                <FlowerIcon data-testid='flowerIcon' className={classes.icon} {...iconProps} />
            )}
            {code.startsWith(MainGroupType.HousePlant) && (
                <PottedPlantIcon data-testid='pottedPlantIcon' className={classes.icon} {...iconProps} />
            )}
            {code.startsWith(MainGroupType.GardenPlant) && (
                <PlantIcon data-testid='plantIcon' className={classes.icon} {...iconProps} />
            )}
        </>
    );
}
