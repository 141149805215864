import { Grid } from '@mui/material';

import { useStyles } from './VariableSizeListItemHeaderStyles';

interface IProps {
    readonly header: string;
}

export default function VariableSizeListItemHeader(props: IProps) {
    const { header } = props;
    const { classes } = useStyles();

    return (
        <Grid item xs={12} className={classes.characterSectionHeader} data-testid='groupHeader'>
            {header}
        </Grid>
    );
}
