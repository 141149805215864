import { Box, SvgIconProps } from '@mui/material';

interface IProps extends SvgIconProps {
    readonly code: string;
}

export function MainColorIcon(props: IProps) {
    const { code } = props;

    return (
        <Box
            data-testid='colorbox'
            width={20}
            height={20}
            bgcolor={code.toLocaleLowerCase()}
            border={code === 'White' ? '1px solid black' : 'none'}
            margin={'0 5px 0 0'}
            borderRadius={'4px'}
        />
    );
}
