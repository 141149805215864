import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    parent: {
        display: 'flex',
        height: 'calc(100vh - 74px)',
        '@supports (height: 100dvh)': {
            height: 'calc(100dvh - 74px)',
        },
        width: '100vw',
        overflow: 'hidden',
    },
    content: {
        position: 'relative',
        flexGrow: 1,
        padding: theme.spacing(0, 2),
        height: '100%',
        overflow: 'auto',
    },
}));
