import { PreSalesActions } from '@store/Actions/preSalesActions';

export interface PreSalesState {
    orderQuantity: { [supplyLineId: string]: number };
    availableSupplyQuantity: { [supplyLineId: string]: number };
}

export const initialState: PreSalesState = {
    orderQuantity: {},
    availableSupplyQuantity: {},
};

export function preSalesReducer(state = initialState, action: PreSalesActions): PreSalesState {
    switch (action.type) {
        case 'ADD_PRESALE':
            return {
                ...state,
                orderQuantity: {
                    ...state.orderQuantity,
                    [action.supplyLineId]: action.numberOfPieces,
                },
                availableSupplyQuantity: {
                    ...state.availableSupplyQuantity,
                    [action.supplyLineId]: action.available - action.numberOfPieces,
                },
            };
        case 'UPDATE_PRESALE':
            return {
                ...state,
                orderQuantity: {
                    ...state.orderQuantity,
                    [action.supplyLineId]: state.orderQuantity[action.supplyLineId] + action.numberOfPieces,
                },
                availableSupplyQuantity: {
                    ...state.availableSupplyQuantity,
                    [action.supplyLineId]: action.available - action.numberOfPieces,
                },
            };
        case 'UPDATE_PRESALES': {
            const newOrders = action.presalePurchaseOrderQuantities.reduce(
                (acc, cur) => ({ ...acc, [cur.clockSupplyLineId]: cur.numberOfPieces }),
                {},
            );
            return {
                ...state,
                orderQuantity: newOrders,
            };
        }
        case 'UPDATE_SUPPLY_AVAILABILITY':
            return {
                ...state,
                availableSupplyQuantity: {
                    ...state.availableSupplyQuantity,
                    [action.supplyLineId]: action.available,
                },
            };
        default:
            return state;
    }
}
