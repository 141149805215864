import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

interface IProps {
    readonly children?: ReactNode;
}

export interface IBreakpointContext {
    currentBreakpoint: string;
    isBreakpointSmall: boolean;
    isBreakpointMedium: boolean;
    isBreakpointLarge: boolean;
}

const initialContext: IBreakpointContext = {
    currentBreakpoint: 'small',
    isBreakpointSmall: true,
    isBreakpointMedium: false,
    isBreakpointLarge: false,
};

const BreakpointContext = createContext(initialContext);

export function BreakpointProvider(props: IProps) {
    const { children } = props;
    const [breakpoints, setBreakpoints] = useState(getBreakpoints());

    useEffect(() => {
        function handleResize() {
            const nextBreakpoints = getBreakpoints();
            if (breakpoints.currentBreakpoint !== nextBreakpoints.currentBreakpoint) {
                setBreakpoints(nextBreakpoints);
            }
        }

        addEventListener('resize', handleResize);

        return () => {
            removeEventListener('resize', handleResize);
        };
    }, [breakpoints.currentBreakpoint]);

    return <BreakpointContext.Provider value={breakpoints}>{children}</BreakpointContext.Provider>;
}

export function useBreakpointContext() {
    return useContext(BreakpointContext);
}

function getBreakpoints() {
    const isMedium = isBreakpoint(BreakpointKey.m);
    const isLarge = isBreakpoint(BreakpointKey.l);
    const isBreakpointSmall = !isMedium && !isLarge;
    const isBreakpointMedium = isMedium && !isLarge;
    const isBreakpointLarge = isLarge;

    return {
        isBreakpointSmall,
        isBreakpointMedium,
        isBreakpointLarge,
        currentBreakpoint: (isBreakpointMedium && 'm') || (isBreakpointLarge && 'l') || 's',
    };
}

function isBreakpoint(size: BreakpointKey) {
    const query = getMediaQueryString(size);

    return matchMedia(query).matches;
}

function getMediaQueryString(size: BreakpointKey) {
    return `only screen and ${BREAKPOINTS[size] || ''}`;
}

enum BreakpointKey {
    m = 'm',
    l = 'l',
    xl = 'xl',
}

const BREAKPOINTS_IN_PX: Record<BreakpointKey, number> = {
    m: 900,
    l: 1200,
    xl: 1536,
};

const BREAKPOINTS: Record<BreakpointKey, string> = {
    m: `(min-width: ${BREAKPOINTS_IN_PX.m}px)`,
    l: `(min-width: ${BREAKPOINTS_IN_PX.l}px)`,
    xl: `(min-width: ${BREAKPOINTS_IN_PX.xl}px)`,
};
