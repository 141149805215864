import IQueueMarking from '@contracts/IQueueMarking';
import { addMarkingToQueue, updateMarkingInQueue } from '@store/Actions/markingQueueActions';
import { useMarkingQueueContext } from '@store/Providers/MarkingQueueProvider';
import ScanRow from '../ScanRow/ScanRow';

export interface IMockedScanRowsProps {
    readonly barcode: string;
}

export default function MockedScanRows(props: IMockedScanRowsProps) {
    const { barcode } = props;
    const letters = ['A', 'B', 'C', 'D', 'E'];

    const {
        state: { queue },
        dispatch,
    } = useMarkingQueueContext();

    const markingInQueue = queue.find(markingInQueue => markingInQueue.barcode === barcode);

    function handleOfflineMarking(marked: boolean, index: number) {
        const newIndex = markingInQueue ? [...markingInQueue.index] : [];
        const marking: IQueueMarking = {
            barcode,
            index: marked ? [...newIndex, index] : newIndex.filter(i => i !== index),
        };

        dispatch(markingInQueue ? updateMarkingInQueue(marking) : addMarkingToQueue(marking));
    }

    function isMarked(index: number) {
        return markingInQueue?.index.includes(index);
    }

    return (
        <>
            {letters.map((letter, index) => (
                <ScanRow
                    key={letter}
                    characterIndex={letter}
                    onOfflineMark={marked => handleOfflineMarking(marked, index)}
                    offlineMarked={isMarked(index)}
                />
            ))}
        </>
    );
}
