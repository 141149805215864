import { PropsWithChildren } from 'react';

import { useStyles } from './AppLayoutStyles';

export default function AppLayout(props: Readonly<PropsWithChildren>) {
    const { children } = props;
    const { classes } = useStyles();

    return (
        <div className={classes.parent}>
            <div className={classes.content}>{children}</div>
        </div>
    );
}
