import { ForwardedRef, forwardRef } from 'react';
import { LoadingPage } from '@floriday/floriday-ui';
import { Grid, Typography } from '@mui/material';
import {
    FilterOption,
    SupplyFilterType,
} from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

import { ISingleColumnContainer } from '@features/Filtering/Contracts/ISingleColumnContainer';
import { ITwoColumnsContainer } from '@features/Filtering/Contracts/ITwoColumnsContainer';
import { namespaces } from '@root/i18n';
import { useStyles } from '../../FilterItemsSelectorStyles';
import VariableSizeListItem from '../VariableSizeListItem/VariableSizeListItem';

interface IProps {
    readonly filterItems: ISingleColumnContainer[] | ITwoColumnsContainer[];
    readonly currentItems: FilterOption[];
    readonly showCount: boolean;
    readonly showFilterOptionCode: boolean;
    readonly toggleChange: (item: FilterOption, checked: boolean) => void;
    readonly filterType: SupplyFilterType;
    readonly isLoading?: boolean;
    readonly noResults?: boolean;
}

// eslint-disable-next-line prefer-arrow-callback
const FilterOptionsList = forwardRef(function FilterOptionsList(props: IProps, ref: ForwardedRef<VariableSizeList>) {
    const {
        filterItems,
        currentItems,
        showCount,
        showFilterOptionCode,
        toggleChange,
        filterType,
        isLoading,
        noResults,
    } = props;
    const { classes } = useStyles();
    const { t } = useTranslation(namespaces.supply, { keyPrefix: 'filterItemsSelector' });

    // Row height for header or default item
    function getItemSize(index: number) {
        return filterItems[index]?.header ? 50 : 30;
    }

    return (
        <Grid order={{ xs: 2, md: 1 }} item xs={12} md={8} className={classes.columnContainer}>
            {filterItems.length > 0 && (
                <AutoSizer>
                    {(size: { readonly height: number; readonly width: number }) => (
                        <VariableSizeList
                            itemCount={filterItems.length}
                            itemSize={getItemSize}
                            height={size.height}
                            width={size.width}
                            ref={ref}
                        >
                            {({ index, style }) => (
                                <VariableSizeListItem
                                    style={style}
                                    row={filterItems[index]}
                                    selectedItems={currentItems}
                                    showCount={showCount}
                                    showFilterOptionCode={showFilterOptionCode}
                                    onToggle={toggleChange}
                                    filterType={filterType}
                                />
                            )}
                        </VariableSizeList>
                    )}
                </AutoSizer>
            )}
            {!isLoading && noResults && <Typography>{t('noResult')}</Typography>}
            {isLoading && <LoadingPage />}
        </Grid>
    );
});

export default FilterOptionsList;
