import { useEffect } from 'react';
import { Dialog, DialogTitle, List, ListItemButton, ListItemIcon, ListItemText, Radio } from '@mui/material';
import { UserSetting } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useTranslation } from 'react-i18next';

import i18n, { SupportedLocale, namespaces, supportedLanguages } from '@root/i18n';
import { useBreakpointContext } from '@store/Providers/BreakpointProvider';
import { useUserSettings } from '@store/Providers/UserSettingsProvider';

interface IProps {
    readonly open: boolean;
    readonly onClose: () => void;
}

export default function LanguageSelector(props: IProps) {
    const { open, onClose } = props;
    const currentLanguage = i18n.language as SupportedLocale;
    const { isBreakpointSmall } = useBreakpointContext();
    const { userSettings, isInitialized, updateUserSettings } = useUserSettings();
    const { t } = useTranslation(namespaces.general);

    async function applyLanguageChange(language: string) {
        await i18n.changeLanguage(language);
        window.location.reload();
    }

    async function onLanguageChange(language: string) {
        onClose();

        await i18n.changeLanguage(language);
        const updatedUserSettings: UserSetting = {
            ...userSettings,
            language: language.substring(0, 2),
        };

        updateUserSettings(updatedUserSettings, () => window.location.reload());
    }

    useEffect(() => {
        if (userSettings.language != null) {
            const userSettingLanguage = userSettings.language;

            if (!supportedLanguages[userSettingLanguage].includes(currentLanguage)) {
                applyLanguageChange(supportedLanguages[userSettingLanguage][0]);
            }
        }
    }, [isInitialized]);

    return (
        <Dialog fullScreen={isBreakpointSmall} open={open} onClose={onClose} maxWidth='xs' fullWidth>
            <DialogTitle>{t('language.language')}</DialogTitle>
            <List disablePadding data-testid='languageList'>
                <ListItemButton onClick={() => onLanguageChange('nl-NL')}>
                    <ListItemIcon>
                        <Radio color='primary' disableRipple checked={currentLanguage === 'nl-NL'} />
                    </ListItemIcon>
                    <ListItemText primary={t('language.nl')} />
                </ListItemButton>
                <ListItemButton onClick={() => onLanguageChange('en-US')}>
                    <ListItemIcon>
                        <Radio color='primary' disableRipple checked={currentLanguage === 'en-US'} />
                    </ListItemIcon>
                    <ListItemText primary={t('language.en')} />
                </ListItemButton>
                <ListItemButton onClick={() => onLanguageChange('de-DE')}>
                    <ListItemIcon>
                        <Radio color='primary' disableRipple checked={currentLanguage === 'de-DE'} />
                    </ListItemIcon>
                    <ListItemText primary={t('language.de')} />
                </ListItemButton>
            </List>
        </Dialog>
    );
}
