import { FilterOption } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';

export interface ISingleColumnContainer {
    header?: FilterOption;
    item?: FilterOption;
}

export function isSingleColumn(row: unknown): row is ISingleColumnContainer {
    return !!row && typeof row === 'object' && 'item' in row;
}
