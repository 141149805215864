import { useEffect, useState } from 'react';

import { INPUT_DEBOUNCE_TIME } from '@constants/debounceConstants';

export function useHoverDebounce(element?: HTMLElement) {
    const [hovered, setHovered] = useState(false);

    useEffect(() => {
        const debounceHover = element && setTimeout(() => setHovered(true), INPUT_DEBOUNCE_TIME);

        if (!element) setHovered(false);

        return () => {
            if (debounceHover) clearTimeout(debounceHover);
        };
    }, [element]);

    return hovered;
}
