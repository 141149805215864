import { Dispatch, createContext, useReducer, useContext, ReactNode, useMemo } from 'react';

import { MarkingQueueActions } from '@store/Actions/markingQueueActions';
import { initialState, MarkingQueueState, markingQueueReducer } from '@store/Reducers/markingQueueReducer';

interface IMarkingQueueContext {
    state: MarkingQueueState;
    dispatch: Dispatch<MarkingQueueActions>;
}

interface IProps {
    readonly children?: ReactNode;
}

const initialContext: IMarkingQueueContext = {
    state: initialState,
    dispatch: () => undefined,
};

const MarkingQueueContext = createContext(initialContext);

export function MarkingQueueProvider(props: IProps) {
    const { children } = props;
    const [state, dispatch] = useReducer(markingQueueReducer, initialState);

    const value = useMemo<IMarkingQueueContext>(
        () => ({
            state,
            dispatch,
        }),
        [state],
    );

    return <MarkingQueueContext.Provider value={value}>{children}</MarkingQueueContext.Provider>;
}

export function useMarkingQueueContext(): IMarkingQueueContext {
    return useContext(MarkingQueueContext);
}
