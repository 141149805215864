import { ISupplyLineMarkingCommand } from '@features/Supply/Contracts/ISupplyLineMarkingCommand';
import { MarkingsActions } from '@store/Actions/markingsActions';

export interface MarkingsState {
    date: string;
    markings: ISupplyLineMarkingCommand[];
}

export const initialState: MarkingsState = {
    date: '',
    markings: [],
};

export function markingsReducer(state = initialState, action: MarkingsActions): MarkingsState {
    switch (action.type) {
        case 'ADD_MARKINGS': {
            const markingsToAdd = action.markings.filter(
                marking =>
                    !state.markings.some(
                        existingMarking => existingMarking.clockSupplyLineId === marking.clockSupplyLineId,
                    ),
            );
            return {
                ...state,
                markings: [...state.markings, ...markingsToAdd],
            };
        }
        case 'ADD_MARKING':
            return {
                ...state,
                markings: [...state.markings, action.marking],
            };
        case 'UPDATE_MARKING': {
            const markingIndex = state.markings.findIndex(
                marking => marking.clockSupplyLineId === action.marking.clockSupplyLineId,
            );
            return {
                ...state,
                markings:
                    markingIndex < 0
                        ? [...state.markings, action.marking]
                        : state.markings.with(markingIndex, action.marking),
            };
        }
        case 'DELETE_MARKING':
            return {
                ...state,
                markings: state.markings.filter(marking => marking.clockSupplyLineId !== action.id),
            };
        case 'DELETE_MARKINGS':
            return {
                ...state,
                markings: state.markings.filter(marking => !action.ids.includes(marking.clockSupplyLineId)),
            };
        case 'UPDATE_MARKINGS': {
            const isSameDate = state.date === action.date;

            return {
                ...state,
                date: action.date,
                markings: isSameDate
                    ? [
                          ...state.markings.filter(
                              stateMarking =>
                                  !action.markings.some(
                                      marking => marking.clockSupplyLineId === stateMarking.clockSupplyLineId,
                                  ),
                          ),
                          ...action.markings,
                      ]
                    : action.markings,
            };
        }
        default:
            return state;
    }
}
