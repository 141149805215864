import { useEffect, useMemo, useRef, useState } from 'react';
import { ArrowDownIcon, ArrowUpIcon, CloseIcon } from '@floriday/floriday-icons';
import { ToggleIconButton } from '@floriday/floriday-ui';
import {
    Box,
    ButtonGroup,
    ClickAwayListener,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Popper,
} from '@mui/material';
import {
    DigitalAuctionSupplyType,
    Marking,
} from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { IUser } from '@features/Auth/Contracts/IUser';
import { getUserAccount } from '@features/Auth/auth';
import MarkButtonContainer from '@features/Supply/Components/Marking/MarkButton/MarkButtonContainer';
import { namespaces } from '@root/i18n';
import { useBreakpointContext } from '@store/Providers/BreakpointProvider';
import { useStyles } from './MarkButtonMenuStyles';
import AccountsMarkingList from '../AccountsMarkingList/AccountsMarkingList';

interface MarkButtonProps {
    readonly marked: boolean;
    readonly onMark: (marked: boolean, username?: string) => void;
    readonly markings?: Marking[];
    readonly supplyLineId?: string;
    readonly digitalAuctionSupplyType?: DigitalAuctionSupplyType;
    readonly users?: string[];
    readonly comment?: string;
    readonly quantity?: number;
}

export default function MarkButtonMenu(props: MarkButtonProps) {
    const { marked, onMark, markings = [], supplyLineId, digitalAuctionSupplyType, users, comment, quantity } = props;
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const { classes } = useStyles();
    const { t } = useTranslation(namespaces.supply, { keyPrefix: 'markButton' });

    const queryClient = useQueryClient();

    const [userAccount, setUserAccount] = useState<IUser>();
    const { isBreakpointSmall } = useBreakpointContext();

    const sortedAccounts = useMemo(getMarkingAccounts, [users, userAccount]);

    function toggleMenu() {
        if (!menuOpen) {
            queryClient.fetchQuery(['supplyLineMarkings', supplyLineId]);
        }
        setMenuOpen(open => !open);
    }

    function getMarkingAccounts() {
        if (!users) {
            return [];
        }

        const filteredAccounts = users.filter(
            account => account !== userAccount?.email && account !== userAccount?.name,
        );
        const allowedAccounts =
            digitalAuctionSupplyType === DigitalAuctionSupplyType.DigitalAuction
                ? filteredAccounts.filter(account => account.includes('@'))
                : filteredAccounts;

        return allowedAccounts.sort((a, b) => a.localeCompare(b));
    }

    useEffect(() => {
        async function getUser() {
            const account = await getUserAccount();
            if (account) {
                setUserAccount(account);
            }
        }
        getUser();
    }, []);

    return (
        <>
            <ButtonGroup variant='outlined' ref={anchorRef} className={classes.buttonGroup}>
                <MarkButtonContainer onChange={onMark} selected={!!marked} comment={comment} quantity={quantity} />
                <ToggleIconButton
                    size='small'
                    disabled={!users}
                    onClick={toggleMenu}
                    className={classes.squareIconButton}
                    data-testid='markMenuButton'
                    icon={
                        menuOpen ? (
                            <ArrowUpIcon className={classes.arrowIcons} />
                        ) : (
                            <ArrowDownIcon className={classes.arrowIcons} />
                        )
                    }
                    isSelected={false}
                    color='primary'
                />
            </ButtonGroup>
            {isBreakpointSmall ? (
                <Dialog fullScreen open={menuOpen}>
                    <DialogTitle className={classes.dialogTitle}>
                        {t('markForAnother')}
                        <IconButton className={classes.closeButton} onClick={() => setMenuOpen(false)} size='large'>
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <AccountsMarkingList accounts={sortedAccounts} onChange={onMark} markings={markings} />
                    </DialogContent>
                </Dialog>
            ) : (
                <Popper className={classes.popper} open={menuOpen} anchorEl={anchorRef.current} placement='bottom-end'>
                    <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                        <Paper elevation={2}>
                            <Box className={classes.paperHeader}>{t('markForAnother')}</Box>
                            <AccountsMarkingList accounts={sortedAccounts} onChange={onMark} markings={markings} />
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            )}
        </>
    );
}
