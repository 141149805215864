export enum SupplyOverviewColumnType {
    Clock = 'Clock',
    AuctioningSequence = 'AuctioningSequence',
    Product = 'Product',
    Supplier = 'Supplier',
    FSICertificate = 'FSICertificate',
    CountryOfOrigin = 'CountryOfOrigin',
    Characteristics = 'Characteristics',
    PositiveCharacteristics = 'PositiveCharacteristics',
    NegativeCharacteristics = 'NegativeCharacteristics',
    Quality = 'Quality',
    Quantity = 'Quantity',
    BatchReference = 'BatchReference',
    Price = 'Price',
    Buy = 'Buy',
    PreSaleOrders = 'PreSaleOrders',
    Marking = 'Marking',
    MarkingQuantity = 'MarkingQuantity',
    MarkingRemark = 'MarkingRemark',
    PreSaleQuantity = 'PreSaleQuantity',
    AuctionLocation = 'AuctionLocation',
    MainColor = 'MainColor',
    MainGroupCode = 'MainGroupCode',
    PackageTypeCode = 'PackageTypeCode',
    LastCommercialMutationMoment = 'LastCommercialMutationMoment',
    QualityIndexClassification = 'QualityIndexClassification',
    IsAuctioned = 'IsAuctioned',
    ProductGroup = 'ProductGroup',
    LoadCarriers = 'LoadCarriers',
}
