import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
    label: {
        cursor: 'pointer',
        whiteSpace: 'pre-wrap',
    },
    checkbox: {
        minWidth: '32px',
    },
}));
