import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    alphabetContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    variableSizeContainer: {
        maxHeight: '400px',
    },
    columnContainer: {
        height: '400px', //needed for rendering items!
    },
    text: {
        fontWeight: 'bold',
    },
    filterContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    divider: {
        height: '100%',
    },
}));
