import { useEffect, useMemo, useRef, useState } from 'react';
import { ArrowBackwardIcon } from '@floriday/floriday-icons';
import { Link, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import type { BarcodeCaptureListener } from 'scandit-web-datacapture-barcode';

import ScanResult from '@features/Scan/Components/ScannerComponent/Components/ScanResult/ScanResult';
import { useNavigatorOnLine } from '@root/Hooks/useNavigatorOnLine';
import { namespaces } from '@root/i18n';
import NoConnectionIcon from '@resources/Icons/NoConnectionIcon';
import { createOnScan, onMount, useSDK } from '@store/Providers/SdkProvider';
import { useStyles } from './ScannerComponentStyles';

export default function ScannerComponent(): JSX.Element {
    const host = useRef<HTMLDivElement | null>(null);
    const { classes } = useStyles();
    const { t } = useTranslation(namespaces.general);
    const [searchParams] = useSearchParams();
    const { loaded, sdk } = useSDK();
    const [barcode, setBarcode] = useState<string | null>(null);
    const currentBarcode = searchParams.get('barcode') ? searchParams.get('barcode') : barcode;
    const isOnline = useNavigatorOnLine();

    function resetScanner() {
        if (searchParams.get('barcode')) {
            searchParams.delete('barcode');
        }
        setBarcode(null);
    }
    const onScan = useMemo<BarcodeCaptureListener>(() => createOnScan(sdk, setBarcode), [sdk]);

    useEffect(() => {
        if (!barcode) {
            onMount(loaded, host, sdk, onScan);
        }

        return () => {
            if (loaded) {
                sdk.removeBarcodeCaptureListener(onScan);
                sdk.detachFromElement();
            }
        };
    }, [loaded, sdk, onScan, barcode]);

    return (
        <div className={classes.container}>
            {!isOnline && (
                <div className={classes.networkContainer} data-testid='networkContainer'>
                    <NoConnectionIcon />
                    <span className={classes.offlineText}>{t('scan.offline')}</span>
                </div>
            )}
            {currentBarcode ? (
                <Paper data-testid='scannerHeader' className={`${classes.paper} ${classes.resultsContainer}`}>
                    <Link className={classes.backLinkContainer} onClick={resetScanner} data-testid='backLink'>
                        <ArrowBackwardIcon className={classes.backLinkArrow} />
                        <span className={classes.backLinkText}>{t('scan.backLinkText')}</span>
                    </Link>
                    <ScanResult barcode={currentBarcode?.slice(0, 12) ?? ''} />
                </Paper>
            ) : (
                <Paper data-testid='scannerHeader' className={`${classes.paper} ${classes.header}`}>
                    <Typography className={classes.title}>{t('scan.barcodeScanner')}</Typography>
                    {t('scan.instructions')}
                </Paper>
            )}
            <div className={classes.scannerContainer} ref={host} />
        </div>
    );
}
