import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import OfflineContainer from '@features/Scan/Components/OfflineContainer/OfflineContainer';
import ScannerComponent from '@features/Scan/Components/ScannerComponent/ScannerComponent';
import { namespaces } from '@root/i18n';
import { MarkingQueueProvider } from '@store/Providers/MarkingQueueProvider';
import { useScanClaimContext } from '@store/Providers/ScanClaimProvider';
import SDKProvider from '@store/Providers/SdkProvider';

export default function ScanOverview() {
    const { t } = useTranslation(namespaces.general);
    const { hasPaaScanClaim } = useScanClaimContext();

    if (!hasPaaScanClaim) {
        return <Box>{t('genericErrors.scanAccessError')}</Box>;
    }

    return (
        <SDKProvider>
            <MarkingQueueProvider>
                <OfflineContainer>
                    <ScannerComponent />
                </OfflineContainer>
            </MarkingQueueProvider>
        </SDKProvider>
    );
}
