/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable no-console */
import { capitalize } from '@mui/material';
import dayjs from 'dayjs';
import i18next, { TFunction, Resource } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
export type SupportedLocale = 'nl-NL' | 'en-US' | 'de-DE';
export const locales: readonly SupportedLocale[] = ['nl-NL', 'en-US', 'de-DE'];

class NamespaceDefinitions {
    general = 'general' as const;
    supply = 'supply' as const;
    order = 'order' as const;
}
export const namespaces = new NamespaceDefinitions();

const localeResources: Resource = {};
for (const locale of locales) {
    const shortLocal = locale.substring(0, 2);
    localeResources[locale] = {};
    localeResources[locale][namespaces.general] = require(`../locales/${locale}/general.json`);
    localeResources[locale][namespaces.supply] = require(`../locales/${locale}/supply.json`);
    localeResources[locale][namespaces.order] = require(`../locales/${locale}/order.json`);
    require(`dayjs/esm/locale/${shortLocal}.js`);
}

export const supportedLanguages: Record<string, SupportedLocale[]> = {
    nl: ['nl-NL'],
    en: ['en-US'],
    de: ['de-DE'],
    default: ['en-US'],
};

const frozenLocales = Object.freeze(locales);
export function localesImmutable(): readonly string[] {
    return frozenLocales;
}

export type FloridayPriceType = { currency?: string; value?: number };
export function formatPrice(price: FloridayPriceType) {
    if (!price) {
        return '';
    }

    return new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: price.currency?.toUpperCase(),
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
    }).format(Number(price.value));
}

export function formatDate(inputDateString: string): string {
    const inputDate = new Date(inputDateString);

    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    };
    return new Intl.DateTimeFormat(i18n.language, options).format(inputDate);
}

export function formatLongDate(inputDateString: string): string {
    const inputDate = new Date(inputDateString);

    const options: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    };
    return new Intl.DateTimeFormat(i18n.language, options).format(inputDate);
}

const i18n = i18next.createInstance();

function getFallbackLanguages(language: string) {
    return supportedLanguages[language] || supportedLanguages.default;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
i18n.use(LanguageDetector).init(
    {
        resources: localeResources,
        fallbackLng: supportedLanguages,
        ns: [namespaces.general, namespaces.supply, namespaces.order],
        defaultNS: namespaces.general,
        react: { useSuspense: true },
        debug: false,
        cache: { enabled: true },
        saveMissing: true,
        missingKeyHandler: (_lngs, ns, key) => {
            console.error(
                `The translation for key '${key}' was not found in ${ns}. Did you import the correct i18next instance?`,
            );
        },
        interpolation: {
            format(value: any, format?: string, lng?: string) {
                if (value instanceof Date)
                    return capitalize(
                        dayjs(value)
                            .locale(lng?.substring(0, 2) ?? '')
                            .format(format),
                    );
                if (dayjs.isDayjs(value))
                    return capitalize(
                        dayjs(value)
                            .locale(lng?.substring(0, 2) ?? '')
                            .format(format),
                    );
                return value;
            },
        },
    },
    () => {
        const currentLanguage = i18n.language;
        document.documentElement.lang = currentLanguage;
        if (!locales.includes(currentLanguage as SupportedLocale)) {
            const fallbackLanguage = getFallbackLanguages(currentLanguage)[0];
            i18n.changeLanguage(fallbackLanguage);
        }
    },
);

// .bind(i18n) is used here to bind the correct value for `this` as i18n
// relies on the JavaScript semantics with regards to `this`-references.
const changeLanguageInternal: typeof i18n.changeLanguage = i18n.changeLanguage.bind(i18n);
// Replace `changeLanguage` so that we can send the languge change notification.
i18n.changeLanguage = (lng: string, callback?: (error: any, t: TFunction) => void) => {
    document.documentElement.lang = lng;

    localeChangeListeners.forEach(l => l(lng as SupportedLocale));
    return changeLanguageInternal(lng, callback);
};

export default i18n;

/** Gets the currently used fallback language. */
const localeChangeListeners = new Set<(lng: SupportedLocale) => void>();
/**
 * Registers a listener function that will be invoked when the locale has changed.
 * @param listener The callback function to call when the locale has changed.
 * @returns A function that, when called, will deregister the provided listener.
 */
export function registerLocaleChangeListener(listener: (lng: SupportedLocale) => void): () => void {
    localeChangeListeners.add(listener);
    return function () {
        localeChangeListeners.delete(listener);
    };
}
