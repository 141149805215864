import { useEffect } from 'react';
import { UserMenuRadioItem } from '@floriday/floriday-ui';
import { Preset } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useIntercom } from 'react-use-intercom';

import { SELECTED_PRESET_KEY } from '@constants/storageKeys';
import { useIntercomContext } from '@store/Providers/IntercomProvider';
import { usePresetContext } from '@store/Providers/PresetsProvider';
import { loadFromLocalStorage } from '@utils/LocalStorageUtils';
import { useStyles } from './PresetSelectorStyles';

export default function PresetSelector() {
    const { presets, selectedPreset, setSelectedPreset } = usePresetContext();
    const { update: updateIntercomProps } = useIntercom();
    const { intercomProps } = useIntercomContext();
    const { classes } = useStyles();

    function handlePresetChange(preset: Preset) {
        setSelectedPreset(preset);

        updateIntercomProps({
            ...intercomProps,
            company: {
                companyId: preset.organizationId,
                name: preset.organizationName,
                customAttributes: { company_administration_number: preset.organizationRelationNumber },
            },
            customAttributes: {
                organizationId: preset.organizationId,
            },
        });

        localStorage.setItem(SELECTED_PRESET_KEY, JSON.stringify(preset));
    }

    useEffect(() => {
        const presetFromLocalStorage = loadFromLocalStorage(SELECTED_PRESET_KEY);
        setSelectedPreset(presetFromLocalStorage);
    }, []);

    return (
        <>
            {presets.map((preset: Preset) => (
                <UserMenuRadioItem
                    disabled={preset.presetBlocked}
                    key={preset.presetId}
                    primary={preset.presetName}
                    secondary={
                        <span
                            className={classes.secondaryText}
                        >{`${preset.organizationName} (${preset.organizationRelationNumber})`}</span>
                    }
                    onClick={() => {
                        handlePresetChange(preset);
                        window.location.reload();
                    }}
                    checked={preset.presetId === selectedPreset?.presetId}
                />
            ))}
        </>
    );
}
