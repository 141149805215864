import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    backLinkContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 0, 1, 0),
        marginBottom: theme.spacing(2),
    },
    backLinkArrow: {
        color: theme.palette.grey[600],
        marginRight: theme.spacing(1.5),
    },
    backLinkText: {
        color: '#000000',
        fontWeight: 'normal',
    },
    container: {
        margin: theme.spacing(0, -2),
        width: '100vw',
    },
    paper: {
        borderRadius: '0',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        boxShadow: '0px 4px 10px 0px #0000001A',
    },
    header: {
        marginBottom: theme.spacing(2),
    },
    resultsContainer: {
        height: 'calc(100vh - 74px)',
        '@supports (height: 100dvh)': {
            height: 'calc(100dvh - 74px)',
        },
    },
    title: {
        color: '#000000',
        fontSize: '20px',
        fontWeight: 800,
        lineHeight: '32px',
        marginBottom: theme.spacing(1),
    },
    networkContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        backgroundColor: theme.palette.error.light,
        padding: theme.spacing(3),
        gap: theme.spacing(1),
    },
    offlineText: {
        marginRight: theme.spacing(2),
    },
    scannerContainer: {
        borderRadius: '10px',
        margin: theme.spacing(2),
        marginBottom: 0,
        overflow: 'hidden',
    },
}));
