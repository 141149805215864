import { Box, Typography } from '@mui/material';
import { Marking } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';

import MarkButtonContainer from '@features/Supply/Components/Marking/MarkButton/MarkButtonContainer';
import { useStyles } from './AccountsMarkingListStyles';

interface IProps {
    readonly accounts: string[];
    readonly onChange: (selected: boolean, user: string) => void;
    readonly markings: Marking[];
}

export default function AccountsMarkingList(props: IProps) {
    const { accounts, onChange, markings } = props;
    const { classes } = useStyles();

    return (
        <Box className={classes.accountsContainer}>
            {accounts.map(user => (
                <Box key={user} className={classes.accountsItem} data-testid='markingAccount'>
                    <MarkButtonContainer
                        onChange={selected => {
                            onChange(selected, user);
                        }}
                        selected={!!markings?.some(marking => marking.userName === user)}
                    />
                    <Typography className={classes.accountsItemLabel}>{user}</Typography>
                </Box>
            ))}
        </Box>
    );
}
