import { Box } from '@mui/material';
import { ClockSupplyLine } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useTranslation } from 'react-i18next';

import { namespaces } from '@root/i18n';
import ScanRow from '../ScanRow/ScanRow';

export interface IScanRowsProps {
    readonly clockSupplyLines: ClockSupplyLine[];
    readonly markingAccounts: string[];
}

export default function ScanTable(props: IScanRowsProps) {
    const { clockSupplyLines, markingAccounts } = props;
    const { t } = useTranslation(namespaces.supply);

    function getCharacterIndex(index: number): string {
        return String.fromCharCode(97 + index).toUpperCase();
    }

    return (
        <>
            {clockSupplyLines.length > 0 ? (
                clockSupplyLines.map((clockSupplyLine, index) => (
                    <ScanRow
                        key={clockSupplyLine.id}
                        characterIndex={getCharacterIndex(index)}
                        clockSupplyLine={clockSupplyLine}
                        markingAccounts={markingAccounts}
                    />
                ))
            ) : (
                <Box>{t('scan.noSupplyFound')}</Box>
            )}
        </>
    );
}
