import { makeStyles } from 'tss-react/mui';

export const sharedStyles = makeStyles()(theme => ({
    flexiblePage: {
        maxWidth: '1440px',
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
    },
    badge: {
        width: '22px',
        height: '22px',
        alignItems: 'center',

        '& .MuiBadge-badge': {
            top: 'auto',
            background: theme.palette.secondary.main,
            transform: 'translate(0)',
            color: theme.palette.secondary.contrastText,
        },
    },
}));
