import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    container: {
        maxWidth: '1440px',
        margin: 'auto',

        [theme.breakpoints.down('sm')]: {
            minHeight: 56,
            backgroundColor: '#FFFFFF',
            display: 'flex',
            padding: theme.spacing(1),
        },
    },
    title: {
        margin: 0,
        fontSize: 36,
        fontWeight: 400,
        lineHeight: 1.2,

        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(2),
        },

        [theme.breakpoints.down('sm')]: {
            flex: '1 1 auto',
            textAlign: 'center',
            marginRight: '20px',
        },
    },
    backLinkContainer: {
        color: theme.palette.grey['600'],
        display: 'flex',
        gap: theme.spacing(1),
        cursor: 'pointer',

        '& svg': {
            fontSize: 20,
        },

        '&:hover': {
            color: theme.palette.text.primary,
        },

        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(2),
        },

        [theme.breakpoints.down('sm')]: {
            margin: 'auto',
        },
    },
    backLinkText: {
        lineHeight: '20px',
        fontSize: '16px',
        fontWeight: 700,

        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));
