import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';

export default function CartIcon(props: SvgIconProps) {
    return (
        <SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M14.0992 0.747559C13.671 0.747559 13.3125 1.07212 13.27 1.49824L12.9861 4.3459H0.833344C0.58379 4.3459 0.347372 4.45774 0.189086 4.65067C0.0307999 4.8436 -0.0326909 5.09732 0.0160688 5.34206L1.45002 12.5396C1.52768 12.9294 1.86982 13.2101 2.26729 13.2101H9.93521V11.5434H6.2491L6.05647 9.61071H12.4617L14.1708 9.26785L14.5698 5.2619L14.8536 2.41423H17.3266V0.747559H14.0992ZM4.38154 9.61071L4.57417 11.5434H2.95098L2.56593 9.61071H4.38154ZM8.68378 7.94404H5.89035L5.69784 6.01258H8.87612L8.68378 7.94404ZM4.21542 7.94404L4.0229 6.01258H1.84908L2.23388 7.94404H4.21542ZM10.3587 7.94404L10.551 6.01258H12.8201L12.6277 7.94404H10.3587ZM12.2552 11.4693L20 9.91558L18.4507 17.6874L15.9898 15.2176L11.9696 19.2525L10.7889 18.0761L14.8135 14.0369L12.2552 11.4693ZM3.70227 15.3692C3.37342 15.3692 3.10166 15.6376 3.10166 15.975C3.10166 16.3125 3.37342 16.5808 3.70227 16.5808C4.03112 16.5808 4.30288 16.3125 4.30288 15.975C4.30288 15.6376 4.03112 15.3692 3.70227 15.3692ZM1.43498 15.975C1.43498 14.7228 2.44723 13.7025 3.70227 13.7025C4.95731 13.7025 5.96955 14.7228 5.96955 15.975C5.96955 17.2272 4.95731 18.2475 3.70227 18.2475C2.44723 18.2475 1.43498 17.2272 1.43498 15.975Z'
                fill='#380F92'
            />
        </SvgIcon>
    );
}
