import { UserMenuSubHeader } from '@floriday/floriday-ui';
import { useTranslation } from 'react-i18next';

import { namespaces } from '@root/i18n';
import PresetSelector from './Components/PresetSelector/PresetSelector';

export default function PresetSection() {
    const { t } = useTranslation(namespaces.general);

    return (
        <>
            <UserMenuSubHeader label={t('userMenu.presets')} />
            <PresetSelector />
        </>
    );
}
