export enum OrderOverviewColumnType {
    Product = 'Product',
    Supplier = 'Supplier',
    OrderDate = 'OrderDate',
    Quantity = 'Quantity',
    PricePerItem = 'PricePerItem',
    TotalPriceOfOrder = 'TotalPriceOfOrder',
    Cancel = 'Cancel',
}

export interface OrderOverviewColumnSetting {
    typeName: OrderOverviewColumnType;
    order: number;
}

export const OrderOverviewColumnSettings: OrderOverviewColumnSetting[] = [
    { order: 1, typeName: OrderOverviewColumnType.Product },
    { order: 2, typeName: OrderOverviewColumnType.Supplier },
    { order: 3, typeName: OrderOverviewColumnType.OrderDate },
    { order: 4, typeName: OrderOverviewColumnType.Quantity },
    { order: 5, typeName: OrderOverviewColumnType.PricePerItem },
    { order: 6, typeName: OrderOverviewColumnType.TotalPriceOfOrder },
    { order: 7, typeName: OrderOverviewColumnType.Cancel },
];
