import { Chip, Divider, Grid, List, ListItem, Typography } from '@mui/material';
import {
    FilterOption,
    SupplyFilterType,
} from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useTranslation } from 'react-i18next';

import { FilterPanelSetting, filterPanelSettings } from '@constants/filterPanelSettings';
import MainGroupIcon from '@root/Components/MainGroupIcon/MainGroupIcon';
import { namespaces } from '@root/i18n';
import { useBreakpointContext } from '@store/Providers/BreakpointProvider';
import { sortFilterChipListOptions } from '@utils/FilterOptionUtils';
import { useStyles } from './FilterChipsListStyles';
import { useStyles as useFilterItemsSelectorStyles } from '../../FilterItemsSelectorStyles';

interface IProps {
    readonly allSortedFilterOptions: FilterOption[];
    readonly filterOptions: FilterOption[];
    readonly filterType: SupplyFilterType;
    readonly onDelete: (key: string) => void;
    readonly showCode?: boolean;
}

export function FilterChipsList(props: IProps) {
    const { allSortedFilterOptions, filterOptions, filterType, onDelete, showCode } = props;
    const { classes } = useStyles();
    const { classes: filterItemsSelectorClasses } = useFilterItemsSelectorStyles();
    const { t } = useTranslation(namespaces.supply, { keyPrefix: 'filterItemsSelector' });
    const { isBreakpointSmall } = useBreakpointContext();
    const sortedFilterOptions = sortFilterChipListOptions(enrichOrder(allSortedFilterOptions, filterOptions));

    function enrichOrder(sortedList: FilterOption[], unsortedList: FilterOption[]): FilterOption[] {
        return unsortedList.map(item => {
            const correspondingItem = sortedList.find(i => i.name === item.name);
            if (correspondingItem) {
                return { ...item, order: correspondingItem.order };
            }
            return item;
        });
    }

    function chipText(filterOption: FilterOption) {
        return showCode && filterOption.code ? `${filterOption.name} (${filterOption.code.trim()})` : filterOption.name;
    }

    return (
        <Grid
            order={{ xs: 1, md: 2 }}
            container
            item
            xs={12}
            md={4}
            className={!isBreakpointSmall ? filterItemsSelectorClasses.columnContainer : ''}
            wrap={!isBreakpointSmall ? 'nowrap' : 'wrap'}
        >
            {!isBreakpointSmall && <Divider orientation='vertical' flexItem className={classes.divider} />}
            {sortedFilterOptions.length > 0 ? (
                <List dense disablePadding className={`${classes.list} ${isBreakpointSmall ? classes.listMobile : ''}`}>
                    {sortedFilterOptions.map(filterOption => (
                        <ListItem className={isBreakpointSmall ? classes.listItemMobile : ''} key={filterOption.key}>
                            <Chip
                                data-testid='filterChip'
                                label={
                                    <>
                                        {filterPanelSettings[filterType]?.includes(
                                            FilterPanelSetting.showMainGroupIcon,
                                        ) ? (
                                            <MainGroupIcon code={filterOption.code ?? ''} />
                                        ) : undefined}
                                        {chipText(filterOption)}
                                    </>
                                }
                                title={chipText(filterOption)}
                                onDelete={() => onDelete(filterOption.key)}
                                className={classes.chip}
                            />
                        </ListItem>
                    ))}
                </List>
            ) : (
                <>
                    {!isBreakpointSmall && (
                        <Typography className={classes.noOptions}>{t('noOptionsSelected')}</Typography>
                    )}
                </>
            )}
        </Grid>
    );
}
