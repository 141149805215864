import IQueueMarking from '@contracts/IQueueMarking';
import { MarkingQueueActions } from '@store/Actions/markingQueueActions';

export interface MarkingQueueState {
    queue: IQueueMarking[];
}

export const initialState: MarkingQueueState = {
    queue: [],
};

export function markingQueueReducer(state = initialState, action: MarkingQueueActions): MarkingQueueState {
    switch (action.type) {
        case 'ADD_MARKING_TO_QUEUE':
            return {
                ...state,
                queue: [...state.queue, action.marking],
            };
        case 'UPDATE_MARKING_IN_QUEUE':
            return {
                ...state,
                queue: state.queue.map(marking =>
                    marking.barcode === action.marking.barcode ? action.marking : marking,
                ),
            };
        case 'RESET_QUEUE':
            return {
                ...state,
                queue: [],
            };
        default:
            return state;
    }
}
