import { FilterOption } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';

import { ISingleColumnContainer } from '@features/Filtering/Contracts/ISingleColumnContainer';
import { ITwoColumnsContainer } from '@features/Filtering/Contracts/ITwoColumnsContainer';

export function generateFilterItemList(
    sortedFilterOptions: FilterOption[],
    enableGrouping: boolean,
    singleColumn: boolean,
): ISingleColumnContainer[] | ITwoColumnsContainer[] {
    return singleColumn
        ? generateSingleColumnContainers(sortedFilterOptions, enableGrouping)
        : generateTwoColumnContainers(sortedFilterOptions, enableGrouping);
}

function generateSingleColumnContainers(
    sortedFilterOptions: FilterOption[],
    enableGrouping: boolean,
): ISingleColumnContainer[] {
    return enableGrouping
        ? generateGroupedSingleColumnContainers(sortedFilterOptions)
        : convertToSingleItemContainers(sortedFilterOptions);
}

function generateTwoColumnContainers(
    sortedFilterOptions: FilterOption[],
    enableGrouping: boolean,
): ITwoColumnsContainer[] {
    return enableGrouping
        ? generateGroupedTwoColumnContainers(sortedFilterOptions)
        : splitIntoPairs(sortedFilterOptions);
}

function generateGroupedSingleColumnContainers(sortedFilterOptions: FilterOption[]) {
    const characterList: string[] = sortedFilterOptions.map(item => item.name.charAt(0).toUpperCase());
    const distinctCharacterList = Array.from(new Set(characterList));
    return distinctCharacterList.flatMap(character => {
        const filteredFilterOptions: FilterOption[] = sortedFilterOptions.filter(filterOption =>
            filterOption.name.toUpperCase().startsWith(character),
        );

        const bundledFilterOptions = convertToSingleItemContainers(filteredFilterOptions);

        return [
            {
                header: { key: character, name: `'${character}'`, code: character },
            },
            ...bundledFilterOptions,
        ];
    });
}

function generateGroupedTwoColumnContainers(sortedFilterOptions: FilterOption[]) {
    const characterList: string[] = sortedFilterOptions.map(item => item.name.charAt(0).toUpperCase());
    const distinctCharacterList = Array.from(new Set(characterList));
    return distinctCharacterList.flatMap(character => {
        const filteredFilterOptions: FilterOption[] = sortedFilterOptions.filter(filterOption =>
            filterOption.name.toUpperCase().startsWith(character),
        );

        const bundledFilterOptions = splitIntoPairs(filteredFilterOptions);

        return [
            {
                header: { key: character, name: `'${character}'`, code: character },
            },
            ...bundledFilterOptions,
        ];
    });
}

function convertToSingleItemContainers(array: FilterOption[]) {
    return array.reduce((singleColumnList, currentItem) => {
        singleColumnList.push({ item: currentItem });

        return singleColumnList;
    }, [] as ISingleColumnContainer[]);
}

function splitIntoPairs(array: FilterOption[]) {
    return array.reduce((twoColumnsList, currentItem, currentIndex) => {
        if (currentIndex % 2 === 0) {
            twoColumnsList.push({ leftItem: currentItem, rightItem: { key: '', name: '', code: '' } });
        } else {
            twoColumnsList[twoColumnsList.length - 1].rightItem = currentItem;
        }

        return twoColumnsList;
    }, [] as ITwoColumnsContainer[]);
}
