import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';

export default function CartIcon(props: SvgIconProps) {
    return (
        <SvgIcon width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M27.4279 12.5714C27.4339 15.232 26.3981 17.7893 24.5422 19.6959L26.1571 21.3108C27.4016 20.0321 28.359 18.5027 28.9657 16.8247C29.5723 15.1467 29.814 13.3586 29.6748 11.5797C29.5356 9.80082 29.0187 8.07208 28.1584 6.50883C27.2982 4.94558 26.1145 3.58375 24.6862 2.51422L23.3125 4.34279C24.5947 5.29653 25.635 6.53795 26.3498 7.96715C27.0646 9.39635 27.4339 10.9734 27.4279 12.5714Z'
                fill='#606060'
            />
            <path
                d='M21.7143 12.5714C21.7121 13.7392 21.3524 14.8783 20.6834 15.8354L22.3143 17.4674C23.5584 15.8706 24.1492 13.8608 23.9672 11.8447C23.7851 9.82872 22.8438 7.95721 21.3337 6.60914L19.8091 8.312C20.408 8.84835 20.8872 9.50486 21.2155 10.2388C21.5437 10.9726 21.7137 11.7675 21.7143 12.5714ZM32 30.384L1.616 0L0 1.616L4.24 5.856C2.5456 8.52514 1.92095 11.7354 2.4909 14.8452C3.06085 17.9549 4.78342 20.735 7.31429 22.6297L8.68571 20.8011C6.68527 19.3063 5.29943 17.1325 4.78848 14.688C4.27754 12.2436 4.67665 9.69669 5.91086 7.52571L8.42171 10.0366C7.92052 11.5169 7.8661 13.1119 8.26524 14.6229C8.66438 16.1339 9.49943 17.4941 10.6663 18.5337L12.1909 16.8309C11.5919 16.2946 11.1127 15.6381 10.7844 14.9042C10.4562 14.1702 10.2862 13.3754 10.2857 12.5714C10.2893 12.3627 10.3045 12.1544 10.3314 11.9474L14.8571 16.4731V32H17.1429V18.7589L30.384 32L32 30.384Z'
                fill='#606060'
            />
        </SvgIcon>
    );
}
