import { FilterOption } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';

export interface ITwoColumnsContainer {
    header?: FilterOption;
    leftItem?: FilterOption;
    rightItem?: FilterOption;
}

export function isTwoColumns(row: unknown): row is ITwoColumnsContainer {
    return !!row && typeof row === 'object' && ('leftItem' in row || 'rightItem' in row);
}
