import { SupplyFilterType } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';

import { ITwoColumnsContainer } from '@features/Filtering/Contracts/ITwoColumnsContainer';
import FilterItem, { IProps as IFilterItemProps } from '../../../FilterItem/FilterItem';

interface IProps extends Omit<IFilterItemProps, 'item' | 'selected'> {
    readonly row: ITwoColumnsContainer;
    readonly filterType: SupplyFilterType;
    readonly leftSelected: boolean;
    readonly rightSelected: boolean;
}

export default function VariableSizeListItemDesktop(props: IProps) {
    const { row, leftSelected, rightSelected, showCount, showFilterOptionCode, onToggle, filterType } = props;

    return (
        <>
            {row.leftItem && (
                <FilterItem
                    item={row.leftItem}
                    selected={leftSelected}
                    showCount={showCount}
                    showFilterOptionCode={showFilterOptionCode}
                    onToggle={onToggle}
                    filterType={filterType}
                />
            )}
            {row.rightItem && row.rightItem.name !== '' && (
                <FilterItem
                    item={row.rightItem}
                    selected={rightSelected}
                    showCount={showCount}
                    showFilterOptionCode={showFilterOptionCode}
                    onToggle={onToggle}
                    filterType={filterType}
                />
            )}
        </>
    );
}
