import { useEffect, useState } from 'react';
import { DigitalAuctionSupplyType } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';

import { ISupplyLineMarkingCommand } from '@features/Supply/Contracts/ISupplyLineMarkingCommand';
import { useMarking } from '@features/Supply/Hooks/useMarking';
import { useSupplyLineMarkings } from '@features/Supply/Hooks/useSupplyLineMarkings';
import { useMarkingsContext } from '@store/Providers/MarkingsProvider';
import MarkButtonMenu from './Components/MarkButtonMenu/MarkButtonMenu';

interface MarkButtonProps {
    readonly supplyLineId: string;
    readonly digitalAuctionSupplyType: DigitalAuctionSupplyType;
    readonly users: string[];
    readonly comment?: string;
    readonly quantity?: number;
}

export default function MarkButtonMenuContainer(props: MarkButtonProps) {
    const { supplyLineId, digitalAuctionSupplyType, comment, quantity, users } = props;

    const { createMarking, deleteMarking } = useMarking();
    const { data: markings } = useSupplyLineMarkings(supplyLineId);

    const {
        state: { markings: markingsInContext },
    } = useMarkingsContext();

    const [supplyLineMarking, setSupplyLineMarking] = useState<ISupplyLineMarkingCommand | undefined>();

    const markingDetail = markingsInContext.find(m => m.clockSupplyLineId === supplyLineId);

    function handleRowMarking(marked: boolean, username?: string) {
        if (!username) {
            setSupplyLineMarking(prevValue => ({
                id: prevValue?.id ?? '',
                clockSupplyLineId: supplyLineId ?? '',
                comment: '',
                amount: 0,
                userName: '',
                creationUserName: '',
            }));
        }

        if (marked) {
            createMarking.mutate({
                supplyLineMarkingCommand: {
                    id: supplyLineMarking?.id,
                    clockSupplyLineId: supplyLineId ?? '',
                },
                username,
            });
        } else {
            deleteMarking.mutate({
                clockSupplyLineId: supplyLineId ?? '',
                supplyLineMarkingCommand: !username ? (supplyLineMarking as ISupplyLineMarkingCommand) : undefined,
                username,
            });
        }
    }

    useEffect(() => {
        if (markingDetail) {
            setSupplyLineMarking(markingDetail);
        } else {
            setSupplyLineMarking({
                id: '',
                clockSupplyLineId: '',
                comment: '',
                amount: 0,
                userName: '',
                creationUserName: '',
            });
        }
    }, [markings]);

    return (
        <MarkButtonMenu
            markings={markings ?? []}
            marked={!!markingDetail}
            onMark={handleRowMarking}
            supplyLineId={supplyLineId}
            digitalAuctionSupplyType={digitalAuctionSupplyType}
            users={users}
            comment={comment}
            quantity={quantity}
        />
    );
}
