import { Suspense } from 'react';
import { LoadingPage } from '@floriday/floriday-ui';
import { Outlet } from 'react-router';

export default function SuspenseRoute() {
    return (
        <Suspense fallback={<LoadingPage />}>
            <Outlet />
        </Suspense>
    );
}
