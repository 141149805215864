import { Marking } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { UseQueryOptions, useQuery, QueryKey } from '@tanstack/react-query';

import AuctionPreparationClient from '@api/AuctionPreparationClient';
import { authenticatedRequest, handleApiGetError } from '@api/helper';

export function useSupplyLineMarkings(supplyLineId: string, options?: UseQueryOptions<Marking[] | null>) {
    const client = AuctionPreparationClient.instance.getApiClient();

    function queryFn() {
        return client.getMarkings(supplyLineId);
    }

    return useQuery(['supplyLineMarkings', supplyLineId] as QueryKey, () => authenticatedRequest(queryFn), {
        ...options,
        onError: error => {
            handleApiGetError(error);
        },
        enabled: false,
        cacheTime: 0,
        staleTime: Infinity,
    });
}
