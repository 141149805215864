import { ReactNode } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { useTranslation } from 'react-i18next';

import { namespaces } from '@root/i18n';
import { useStyles } from './CustomErrorBoundaryStyles';

interface IProps {
    readonly children: ReactNode;
}

export default function CustomErrorBoundary({ children }: IProps) {
    const { classes } = useStyles();
    const { t } = useTranslation(namespaces.general, { keyPrefix: 'genericErrors' });

    return (
        <ErrorBoundary fallback={<div className={classes.text}>{t('unexpectedError')}</div>}>{children}</ErrorBoundary>
    );
}
