import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    characterSectionHeader: {
        backgroundColor: theme.palette.grey[50],
        alignSelf: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        marginRight: theme.spacing(2),
        padding: theme.spacing(1, 0, 1, 1),
        fontWeight: 'bold',
    },
}));
