import { useQuery, QueryKey } from '@tanstack/react-query';

import AuctionPreparationClient from '@api/AuctionPreparationClient';
import { authenticatedRequest, handleApiGetError } from '@api/helper';
import { CACHE_TIME, STALE_TIME } from '@constants/cacheTimeConstants';

export function useConfigurationSettings() {
    const client = AuctionPreparationClient.instance.getApiClient();

    function queryFn() {
        return client.getConfigurations();
    }

    return useQuery(['configuration-settings'] as QueryKey, () => authenticatedRequest(queryFn), {
        onError: error => {
            handleApiGetError(error);
        },
        cacheTime: CACHE_TIME,
        staleTime: STALE_TIME,
    });
}
