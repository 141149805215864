import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    pageTopBar: {
        minHeight: '70px',
        margin: '12px 0',
        display: 'flex',
    },
    nameContainer: {
        flex: '500px 0 1',
        display: 'flex',
    },
    actionButtonContainer: {
        width: '100%',
        marginBottom: '24px',
        display: 'flex',
        justifyContent: 'end',
        marginTop: theme.spacing(3),
    },
    markingContainer: {
        height: '60px',
        marginTop: '24px',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
        gap: theme.spacing(8),
    },
    box: {
        margin: '10px',
        whiteSpace: 'nowrap',
        textAlign: 'center',
    },
}));
