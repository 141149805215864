import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    rangeContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: theme.spacing(2),
    },
    dash: {
        margin: theme.spacing(0, 1),
    },
}));
