import { Checkbox } from '@mui/material';

import { useStyles } from './StatefulCheckboxStyles';

interface IProps {
    readonly text: string;
    readonly id: string;
    readonly checked: boolean;
    readonly checkChanged: (event: boolean) => void;
    readonly icon?: React.ReactNode;
}

export default function StatefulCheckbox(props: IProps) {
    const { classes } = useStyles();
    const { checked, checkChanged, text, icon } = props;

    return (
        <>
            <Checkbox
                color='primary'
                className={classes.checkbox}
                id={props.id}
                checked={checked}
                onChange={() => checkChanged(!checked)}
            />
            {icon}
            <label htmlFor={props.id} title={text?.toString()} className={classes.label} data-testid='checkBoxLabel'>
                {props.text}
            </label>
        </>
    );
}
