export interface IGenericError extends Error {
    status: number;
    errorMessages: string[];
    title: string;
}

export function isGenericError(error: unknown): error is IGenericError {
    return (
        !!error &&
        typeof error === 'object' &&
        ('status' in error || 'errorMessages' in error || 'title' in error || 'message' in error)
    );
}
