import { createContext, useContext, useEffect, useState, ReactNode, useMemo } from 'react';
import {
    ClockSupplyLine,
    ClockSupplySearchCommand,
    SearchAfterFields,
    SearchFilterItem,
    SearchRangeFilterItem,
    SortDirection,
    SortField,
    SupplyFilterType,
} from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useSearchParams, useLocation } from 'react-router-dom';

import { SELECTED_AUCTION_DATE_KEY } from '@constants/storageKeys';
import { ViewType } from '@constants/supplyOverviewTypes';
import { auctionDateIsWithinRange, getDefaultAuctionDate } from '@utils/DateUtils';

interface ISearchCommandContext {
    lastSearchParams: string;
    searchCommand: ClockSupplySearchCommand;
    params: URLSearchParams;
    currentView?: ViewType;
    setParams: (params: { [key: string]: string }) => void;
    setAuctionDate: (auctionDate: string) => void;
    setLastSearchParams: (param: string) => void;
    setLastSupplyLine: (clockSupplyLine: ClockSupplyLine) => void;
    setCurrentViewType: (viewType: ViewType) => void;
}

interface IProps {
    readonly children?: ReactNode;
}

const selectedAuctionFromStorage = sessionStorage.getItem(SELECTED_AUCTION_DATE_KEY);
const initialState: ClockSupplySearchCommand = {
    query: '',
    skip: 0,
    take: 100,
    // NOTE: Part of pagination disablement
    // take: Number(localStorage.getItem(PAGE_SIZE_KEY)) || PAGE_SIZES[0],
    sorting: {
        field: SortField.Product,
        direction: SortDirection.Ascending,
    },
    hasMarking: false,
    hasPresale: false,
    searchFilterItems: [],
    searchRangeFilterItems: [],
    auctionDate:
        selectedAuctionFromStorage && auctionDateIsWithinRange(selectedAuctionFromStorage)
            ? selectedAuctionFromStorage
            : getDefaultAuctionDate(),
};

const initialContext: ISearchCommandContext = {
    lastSearchParams: '',
    searchCommand: initialState,
    params: {} as URLSearchParams,
    currentView: undefined,
    setParams: () => undefined,
    setAuctionDate: () => undefined,
    setLastSearchParams: () => undefined,
    setLastSupplyLine: () => undefined,
    setCurrentViewType: () => undefined,
};

const SearchCommandContext = createContext(initialContext);

export function SearchCommandProvider(props: IProps) {
    const { children } = props;
    const storedAuctionDate = sessionStorage.getItem(SELECTED_AUCTION_DATE_KEY);

    const { pathname } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchCommand, setSearchCommand] = useState(initialState);
    const [lastSearchParams, setLastSearchParams] = useState('');
    const [selectedAuctionDate, setSelectedAuctionDate] = useState(
        storedAuctionDate && auctionDateIsWithinRange(storedAuctionDate) ? storedAuctionDate : getDefaultAuctionDate(),
    );
    const [searchAfterFields, setSearchAfterFields] = useState<SearchAfterFields | undefined>();
    const [currentView, setCurrentView] = useState<ViewType>();

    const value = useMemo<ISearchCommandContext>(
        () => ({
            params: searchParams,
            lastSearchParams,
            searchCommand,
            currentView,
            setParams,
            setAuctionDate,
            setLastSearchParams,
            setLastSupplyLine,
            setCurrentViewType,
        }),
        [lastSearchParams, searchCommand],
    );

    function setAuctionDate(auctionDate: string) {
        // NOTE: Part of pagination disablement
        // if (currentView !== ViewType.InfiniteRowView) {
        //     searchParams.set('page', '1');
        // }
        setSearchAfterFields(undefined);
        setSelectedAuctionDate(auctionDate);
        setSearchParams(searchParams);
    }

    function setParams(params: { [key: string]: string }) {
        for (const [key, value] of Object.entries(params)) {
            if (value) {
                searchParams.set(key, value);
            } else {
                searchParams.delete(key);
            }
        }

        // NOTE: Part of pagination disablement
        // if (!Object.keys(params).includes('page') && currentView !== ViewType.InfiniteRowView) {
        //     searchParams.set('page', '1');
        // }

        // if (currentView === ViewType.InfiniteRowView) {
        //     searchParams.delete('page');
        //     searchParams.delete('size');
        //     setSearchAfterFields(undefined);
        // }
        setSearchAfterFields(undefined);
        setSearchParams(searchParams);
    }

    function validateRangeFilters(rangeFilterItems: SearchRangeFilterItem[]) {
        if (!rangeFilterItems) {
            return initialState.searchRangeFilterItems;
        }

        return rangeFilterItems.map(rangeFilterItem => ({
            ...rangeFilterItem,
            min: rangeFilterItem.min && typeof rangeFilterItem.min === 'number' ? rangeFilterItem.min : undefined,
            max: rangeFilterItem.max && typeof rangeFilterItem.max === 'number' ? rangeFilterItem.max : undefined,
        }));
    }

    function setLastSupplyLine(supplyLine?: ClockSupplyLine) {
        if (supplyLine) {
            const searchAfterFields: SearchAfterFields = {
                reference: supplyLine.reference ?? '',
                auctioningSequence: supplyLine.auctioningSequence,
                countryOfOrigin: supplyLine.characteristics?.find(x => x.code === 'S62')?.value ?? '',
                organizationName: supplyLine.organization?.name ?? '',
                productName: supplyLine.productName ?? '',
                lastCommercialMutationMoment: supplyLine.lastCommercialMutationMoment ?? '',
                auctionLocation: supplyLine.auctionLocation ?? '',
                productGroupName: supplyLine.productGroupName ?? '',
            };
            setSearchAfterFields(searchAfterFields);
        }
    }

    // NOTE: Part of pagination disablement
    // function getTake() {
    //     return PAGE_SIZES.includes(Number(searchParams.get('size')))
    //         ? Number(searchParams.get('size'))
    //         : initialState.take;
    // }

    function setCurrentViewType(viewType: ViewType) {
        setSearchAfterFields(undefined);
        setCurrentView(viewType);
    }

    useEffect(() => {
        if (pathname.includes('supply-overview') && currentView) {
            const searchParamsFilters = searchParams.get('filters');
            const searchParamsRangeFilters = searchParams.get('rangeFilters');
            const filterItems: SearchFilterItem[] = searchParamsFilters && JSON.parse(searchParamsFilters);
            const rangeFilterItems: SearchRangeFilterItem[] =
                searchParamsRangeFilters && JSON.parse(searchParamsRangeFilters);
            // NOTE: Part of pagination disablement
            // const page = searchParams.get('page');
            // const skip = page ? (Number(searchParams.get('page')) - 1) * Number(searchParams.get('size')) : 0;
            const updateSearchCommand = {
                query: searchParams.get('query') ?? initialState.query,
                skip: 0,
                // NOTE: Part of pagination disablement
                // skip: currentView === ViewType.InfiniteRowView || skip >= MAX_VISIBLE_ITEM_COUNT ? 0 : skip,
                take: 100,
                // NOTE: Part of pagination disablement
                // take: currentView === ViewType.InfiniteRowView ? 100 : getTake(),
                sorting: {
                    field: (searchParams.get('field') as SortField) || initialState.sorting.field,
                    direction: (searchParams.get('direction') as SortDirection) || initialState.sorting.direction,
                },
                hasMarking:
                    (filterItems?.find(item => item.filterItemType === SupplyFilterType.Marking)?.filterOptionKeys
                        ?.length as number) > 0,
                hasPresale:
                    (filterItems?.find(item => item.filterItemType === SupplyFilterType.Presale)?.filterOptionKeys
                        ?.length as number) > 0,
                searchFilterItems: filterItems ?? initialState.searchFilterItems,
                searchRangeFilterItems: validateRangeFilters(rangeFilterItems),
                auctionDate: selectedAuctionDate,
                searchAfterFields,
            };

            setSearchCommand(updateSearchCommand);
            setLastSearchParams(searchParams.toString());
        }
    }, [searchParams, selectedAuctionDate, searchAfterFields, currentView]);

    return <SearchCommandContext.Provider value={value}>{children}</SearchCommandContext.Provider>;
}

export function useSearchCommandContext(): ISearchCommandContext {
    return useContext(SearchCommandContext);
}
