import { createContext, useContext, ReactNode, useMemo } from 'react';

interface IFeatureFlagsContextValue {
    kvvVisible: boolean;
    markingAutomationsVisible: boolean;
}

interface IProps {
    readonly children?: ReactNode;
}

const FeatureFlagsContext = createContext<IFeatureFlagsContextValue>({
    kvvVisible: false,
    markingAutomationsVisible: false,
});

export function FeatureFlagsProvider({ children }: IProps) {
    const kvvVisible = false;
    const markingAutomationsVisible = false;
    const value = useMemo<IFeatureFlagsContextValue>(
        () => ({ kvvVisible, markingAutomationsVisible }),
        [markingAutomationsVisible],
    );

    return <FeatureFlagsContext.Provider value={value}>{children}</FeatureFlagsContext.Provider>;
}

export function useFeatureFlags(): IFeatureFlagsContextValue {
    return useContext(FeatureFlagsContext);
}
