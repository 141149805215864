import { SupplyFilterType } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useQuery } from '@tanstack/react-query';

import { authenticatedRequest, handleApiGetError } from '@api/helper';

export function useSupplyFilterType<T>(
    supplyFilterType: SupplyFilterType,
    queryFn: () => Promise<T>,
    onSuccess: (supplyFilterType: SupplyFilterType, data: T) => void,
) {
    return useQuery([supplyFilterType], () => authenticatedRequest(queryFn), {
        onSuccess: data => data && onSuccess(supplyFilterType, data),
        onError: error => {
            handleApiGetError(error);
        },
        enabled: true,
    });
}
