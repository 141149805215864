import { LoadingPage } from '@floriday/floriday-ui';
import { Box } from '@mui/material';

import { useStyles } from './OverlayLoadingPageStyles';

interface IProps {
    readonly showLoadingOverlay: boolean;
    readonly children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-require-imports
const loader = require('@resources/Images/animated-loader.gif');

/**
 * Used to place on top of components such as Tables and Lists to indicate new data is being loaded
 * This requires the parent component to be relatively positioned
 */
export default function OverlayLoadingPage({ children, showLoadingOverlay }: IProps) {
    const { classes } = useStyles();

    if (!showLoadingOverlay) {
        return <>{children}</>;
    }

    return (
        <Box position='relative'>
            <div className={classes.overlayContainer}>
                <LoadingPage loader={<img src={loader} alt='Loader' />} />
            </div>

            {children}
        </Box>
    );
}
