import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    header: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            height: '30px',
            width: 'auto',
        },
    },
}));
