import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    toggleButtonGroup: {
        maxWidth: '100%',
        flexWrap: 'wrap',
    },
    toggleButton: {
        borderColor: theme.palette.grey[500],
        minWidth: '32px',
        width: '32px',
    },
}));
