/* eslint-disable no-console */
import {
    SortField,
    SupplyFilterType,
} from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';

import { OrderOverviewColumnType } from '@features/Order/Contracts/OrderOverviewColumnType';
import { SupplyOverviewColumnType } from '@features/Supply/Contracts/SupplyOverviewColumnType';
import i18n, { namespaces } from '@root/i18n';

export function getColumnHeaderTranslation(field: string): string {
    switch (field.toLowerCase()) {
        case SupplyOverviewColumnType.Clock.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.clock`);
        case SupplyOverviewColumnType.AuctioningSequence.toString().toLowerCase():
            // Doesn't describe the content of column well enough but, sort field Header name is clock but it is also ordered by AuctionSequence
            return i18n.t(`${namespaces.supply}:columnHeader.clock`);
        case SupplyOverviewColumnType.Product.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.product`);
        case SupplyOverviewColumnType.Supplier.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.supplier`);
        case SupplyOverviewColumnType.FSICertificate.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.FSICertificate`);
        case SupplyOverviewColumnType.CountryOfOrigin.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.countryOfOrigin`);
        case SupplyOverviewColumnType.Characteristics.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.characteristics`);
        case SupplyOverviewColumnType.PositiveCharacteristics.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.positiveCharacteristics`);
        case SupplyOverviewColumnType.NegativeCharacteristics.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.negativeCharacteristics`);
        case SupplyOverviewColumnType.Quality.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.quality`);
        case SupplyOverviewColumnType.Quantity.toString().toLowerCase():
            // May need to reconsider a different term for "offer" later, doesn't describe the content of column well enough
            return i18n.t(`${namespaces.supply}:columnHeader.offer`);
        case SupplyOverviewColumnType.BatchReference.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.batchReference`);
        case SupplyOverviewColumnType.Price.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.apsPrice`);
        case SupplyOverviewColumnType.Buy.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.buy`);
        case SupplyOverviewColumnType.PreSaleOrders.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.apsOrder`);
        case SupplyOverviewColumnType.Marking.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.marking`);
        case SupplyOverviewColumnType.MarkingQuantity.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.quantityShort`);
        case SupplyOverviewColumnType.MarkingRemark.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.markingRemarkShort`);
        case SupplyOverviewColumnType.PreSaleQuantity.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.preSaleQuantity`);
        case SupplyOverviewColumnType.AuctionLocation.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.auctionLocation`);
        case SupplyOverviewColumnType.MainColor.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.mainColor`);
        case SupplyOverviewColumnType.MainGroupCode.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.mainGroupCode`);
        case SupplyOverviewColumnType.PackageTypeCode.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.package`);
        case SupplyOverviewColumnType.LastCommercialMutationMoment.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.lastCommercialMutationMoment`);
        case SupplyOverviewColumnType.QualityIndexClassification.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.qualityIndexClassification`);
        case SupplyOverviewColumnType.IsAuctioned.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.isAuctioned`);
        case SupplyOverviewColumnType.ProductGroup.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.productGroup`);
        case SupplyOverviewColumnType.LoadCarriers.toString().toLowerCase():
            return i18n.t(`${namespaces.supply}:columnHeader.loadCarriers`);
        default:
            console.error(`The translation for '${field}' was not found`);
            return field;
    }
}

export function getSupplyFilterTypeTranslation(supplyFilterType: SupplyFilterType): string {
    switch (supplyFilterType) {
        case SupplyFilterType.MainGroup:
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.mainGroup`);
        case SupplyFilterType.MainColor:
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.mainColor`);
        case SupplyFilterType.ProductGroup:
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.productGroup`);
        case SupplyFilterType.Product:
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.product`);
        case SupplyFilterType.PotSize:
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.potSize`);
        case SupplyFilterType.Supplier:
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.supplier`);
        case SupplyFilterType.Package:
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.package`);
        case SupplyFilterType.Quality:
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.quality`);
        case SupplyFilterType.FlowerStemLength:
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.flowerStemLength`);
        case SupplyFilterType.Weight:
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.weight`);
        case SupplyFilterType.Marking:
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.marking`);
        case SupplyFilterType.Certificates:
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.certificates`);
        case SupplyFilterType.Presale:
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.presale`);
        case SupplyFilterType.Clock:
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.clock`);
        case SupplyFilterType.PlantHeight:
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.plantHeight`);
        case SupplyFilterType.CountryOfOrigin:
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.countryOfOrigin`);
        case SupplyFilterType.AuctionLocation:
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.auctionLocation`);
        case SupplyFilterType.MaturityStage:
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.maturityStage`);
        default:
            console.error(`The translation for '${supplyFilterType}' was not found in SupplyFilterType`);
            return supplyFilterType;
    }
}

export function getSupplySortOrderTranslation(supplySortOrder: SortField): string {
    switch (supplySortOrder) {
        case SortField.AuctioningSequence:
            return i18n.t(`${namespaces.supply}:supplySortOrder.auctioningSequence`);
        case SortField.CountryOfOrigin:
            return i18n.t(`${namespaces.supply}:supplySortOrder.countryOfOrigin`);
        case SortField.Product:
            return i18n.t(`${namespaces.supply}:supplySortOrder.product`);
        case SortField.Supplier:
            return i18n.t(`${namespaces.supply}:supplySortOrder.supplier`);
        case SortField.LastCommercialMutationMoment:
            return i18n.t(`${namespaces.supply}:supplySortOrder.lastCommercialMutationMoment`);
        case SortField.AuctionLocation:
            return i18n.t(`${namespaces.supply}:supplySortOrder.auctionLocation`);
        case SortField.ProductGroup:
            return i18n.t(`${namespaces.supply}:supplySortOrder.productGroup`);
        default:
            console.error(`The translation for '${supplySortOrder}' was not found in SortField`);
            return supplySortOrder;
    }
}

export function getPresalePurchaseOrderMessagesTranslation(field: string): string {
    switch (field.toLowerCase()) {
        case 'success':
            return i18n.t('supply:presalePurchaseOrderMessages.success');
        case 'presetBlocked'.toLowerCase():
            return i18n.t('supply:presalePurchaseOrderMessages.presetBlocked');
        case 'insufficientSpendingSpace'.toLowerCase():
            return i18n.t('supply:presalePurchaseOrderMessages.insufficientSpendingSpace');
        case 'requestedNumberOfPiecesNotAvailable'.toLowerCase():
            return i18n.t('supply:presalePurchaseOrderMessages.requestedNumberOfPiecesNotAvailable');
        case 'requestedNumberOfPiecesShouldBeMultipelOfPiecesPerPackage'.toLowerCase():
            return i18n.t(
                'supply:presalePurchaseOrderMessages.requestedNumberOfPiecesShouldBeMultipelOfPiecesPerPackage',
            );
        default:
            return i18n.t('supply:presalePurchaseOrderMessages.technicalException');
    }
}

export function getOrderOverviewColumnHeaderTranslation(field: string): string {
    switch (field.toLowerCase()) {
        case OrderOverviewColumnType.Quantity.toString().toLowerCase():
            return i18n.t(`${namespaces.order}:columnHeader.quantity`);
        case OrderOverviewColumnType.Supplier.toString().toLowerCase():
            return i18n.t(`${namespaces.order}:columnHeader.supplier`);
        case OrderOverviewColumnType.PricePerItem.toString().toLowerCase():
            return i18n.t(`${namespaces.order}:columnHeader.pricePerItem`);
        case OrderOverviewColumnType.Product.toString().toLowerCase():
            return i18n.t(`${namespaces.order}:columnHeader.product`);
        case OrderOverviewColumnType.OrderDate.toString().toLowerCase():
            return i18n.t(`${namespaces.order}:columnHeader.orderDate`);
        case OrderOverviewColumnType.TotalPriceOfOrder.toString().toLowerCase():
            return i18n.t(`${namespaces.order}:columnHeader.totalPriceOfOrder`);
        case OrderOverviewColumnType.Cancel.toString().toLowerCase():
            return '';
        default:
            console.error(`The translation for '${field}' was not found`);
            return field;
    }
}

export function getPresalePurchaseOrderCancelMessagesTranslation(field: string): string {
    switch (field.toLowerCase()) {
        case 'success':
            return i18n.t(`${namespaces.order}:presalePurchaseOrderCancelMessages.success`);
        case 'alreadycanceledbycustomer':
            return i18n.t(`${namespaces.order}:presalePurchaseOrderCancelMessages.alreadyCanceledByCustomer`);
        case 'presalepurchaseordernotcancelable':
            return i18n.t(`${namespaces.order}:presalePurchaseOrderCancelMessages.presalePurchaseOrderNotCancelable`);
        case 'supplylinenolongeravailable':
            return i18n.t(`${namespaces.order}:presalePurchaseOrderCancelMessages.supplyLineNoLongerAvailable`);
        case 'presetnotallowed':
        default:
            return i18n.t(`${namespaces.order}:presalePurchaseOrderCancelMessages.technicalException`);
    }
}

export function getColorTranslations(color: string): string {
    switch (color.toLowerCase()) {
        case 'pink':
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.colors.pink`);
        case 'red':
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.colors.red`);
        case 'green':
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.colors.green`);
        case 'blue':
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.colors.blue`);
        case 'black':
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.colors.black`);
        case 'white':
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.colors.white`);
        case 'orange':
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.colors.orange`);
        case 'purple':
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.colors.purple`);
        case 'yellow':
            return i18n.t(`${namespaces.supply}:clockSupplyFilter.colors.yellow`);
        default:
            return color.toLowerCase();
    }
}

export function getSnackbarTranslations(field: string, count?: number): string {
    switch (field.toLowerCase()) {
        case 'markingsaved':
            return i18n.t(`${namespaces.supply}:snackbarMessages.markingSaved`);
        case 'offlinemarkingsaved':
            return i18n.t(`${namespaces.supply}:snackbarMessages.offlineMarkingSaved`, { count });
        case 'filtersaved':
            return i18n.t(`${namespaces.supply}:snackbarMessages.filterSaved`);
        case 'filterdeleted':
            return i18n.t(`${namespaces.supply}:snackbarMessages.filterDeleted`);
        case 'filterordersaved':
            return i18n.t(`${namespaces.supply}:snackbarMessages.filterOrderSaved`);
        case 'columnordersaved':
            return i18n.t(`${namespaces.supply}:snackbarMessages.columnOrderSaved`);
        default:
            return i18n.t(`${namespaces.supply}:snackbarMessages.technicalException`);
    }
}
