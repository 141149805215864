import { useState, useEffect } from 'react';
import { ArrowDownIcon } from '@floriday/floriday-icons';
import { Accordion, AccordionSummary, Badge, AccordionDetails, Link, Typography } from '@mui/material';
import {
    FilterOption,
    RangeFilterItem,
    SupplyFilterType,
    SupplyRangeFilterType,
} from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useTranslation } from 'react-i18next';

import { FilterPanelSetting, filterPanelSettings } from '@constants/filterPanelSettings';
import { namespaces } from '@root/i18n';
import { sharedStyles } from '@utils/SharedStyles';
import { getSupplyRangeFilterType } from '@utils/SupplyFilterTypeUtils';
import RangeFilter from './Components/RangeFilter';
import { useStyles } from './StaticFilterRowStyles';
import { FilterItemsSelector } from '../FilterItemsSelector/FilterItemsSelector';

interface IProps {
    readonly name: string;
    readonly activeOptionCount: number;
    readonly filterType: SupplyFilterType;
    readonly filterOptions: FilterOption[];
    readonly selectedFilterOptions: FilterOption[];
    readonly rangeFilter?: RangeFilterItem;
    readonly showFilterOptionCode: boolean;
    readonly filterOptionsChanged: (filterOptions: FilterOption[]) => void;
    readonly rangeFilterChanged: (supplyRangeFilterType: SupplyRangeFilterType, min?: number, max?: number) => void;
}

export default function StaticFilterRow(props: IProps) {
    const {
        name,
        activeOptionCount,
        filterType,
        filterOptions,
        selectedFilterOptions,
        rangeFilter,
        showFilterOptionCode,
        filterOptionsChanged,
        rangeFilterChanged,
    } = props;

    const { classes } = useStyles();
    const { classes: sharedClasses } = sharedStyles();
    const [expanded, setExpanded] = useState<boolean>(false);
    const [activeFilterCount, setActiveFilterCount] = useState<number>(0);
    const { t } = useTranslation(namespaces.supply);
    const showRangeFilter = filterPanelSettings[filterType]?.includes(FilterPanelSetting.isRangeFilter);
    const hasRangeFilter = !!rangeFilter?.min || !!rangeFilter?.max;

    useEffect(() => {
        setActiveFilterCount(getActiveFilterCount());
    }, [activeOptionCount, hasRangeFilter]);

    function getActiveFilterCount() {
        if (activeOptionCount) {
            return activeOptionCount;
        }

        if (hasRangeFilter) {
            return 1;
        }

        return 0;
    }

    function handleExpansionChange() {
        if (!expanded) {
            const newOptions = selectedFilterOptions.filter(
                (selectedItem: FilterOption) => filterOptions.findIndex(a => a.key === selectedItem.key) !== -1,
            );

            setActiveFilterCount(newOptions.length);
        }

        setExpanded(!expanded);
    }

    function uncheckFilterValues(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        event.preventDefault();
        filterOptionsChanged([]);
        resetRangeFilter();
    }

    function onFilterOptionsChanged(filterOptions: FilterOption[]) {
        filterOptionsChanged(filterOptions);
        resetRangeFilter();
    }

    function onRangeFilterChanged(supplyRangeFilterType: SupplyRangeFilterType, min?: number, max?: number) {
        rangeFilterChanged(supplyRangeFilterType, min, max);
        filterOptionsChanged([]);
    }

    function resetRangeFilter() {
        if (showRangeFilter) {
            const rangeFilterType = getSupplyRangeFilterType(filterType) as SupplyRangeFilterType;

            rangeFilterChanged(rangeFilterType);
        }
    }

    return (
        <Accordion
            className={classes.accordion}
            expanded={expanded}
            onChange={handleExpansionChange}
            slotProps={{ transition: { unmountOnExit: true } }}
            data-testid={filterType.toLowerCase()}
        >
            <AccordionSummary
                expandIcon={<ArrowDownIcon className={classes.accordionIcon} />}
                className={classes.expansionPanelSummary}
            >
                <div className={classes.filterItemName}>{name}</div>
                {activeFilterCount > 0 && (
                    <>
                        <Link
                            component='button'
                            underline='always'
                            onClick={uncheckFilterValues}
                            className={classes.removeFilterValues}
                            data-testid='clearFilterValues'
                        >
                            {t('filters.clearFilter')}
                        </Link>
                        <div className={classes.filterValuesCount} data-testid='filterValuesCount'>
                            <Badge className={sharedClasses.badge} badgeContent={activeFilterCount} max={999} />
                        </div>
                    </>
                )}
            </AccordionSummary>
            <AccordionDetails className={classes.detailsContainer}>
                {showRangeFilter && (
                    <>
                        <Typography className={classes.text}>{t('filterItemsSelector.rangeSelection')}</Typography>
                        <RangeFilter
                            filterType={getSupplyRangeFilterType(filterType) as SupplyRangeFilterType}
                            rangeFilter={rangeFilter}
                            rangeFilterChanged={onRangeFilterChanged}
                        />
                        <Typography className={classes.text}>{t('filterItemsSelector.or')}</Typography>
                    </>
                )}
                <FilterItemsSelector
                    filterOptions={filterOptions}
                    selectedFilterOptions={selectedFilterOptions}
                    filterType={filterType}
                    showFilterOptionCode={showFilterOptionCode}
                    showCount={false}
                    filterChanged={onFilterOptionsChanged}
                />
            </AccordionDetails>
        </Accordion>
    );
}
