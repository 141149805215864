import {
    ClockSupplySearchCommand,
    ClockSupplySearchResult,
} from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { UseQueryOptions, useQuery, QueryKey } from '@tanstack/react-query';

import AuctionPreparationClient from '@api/AuctionPreparationClient';
import { authenticatedRequest, handleApiGetError } from '@api/helper';
import { ViewType } from '@constants/supplyOverviewTypes';

interface IQueryOptions {
    view?: ViewType;
    disabled?: boolean;
}

export function useSupplyLines(
    searchCommand: ClockSupplySearchCommand,
    queryOptions?: IQueryOptions,
    options?: UseQueryOptions<ClockSupplySearchResult | null>,
) {
    const client = AuctionPreparationClient.instance.getApiClient();

    function queryFn() {
        return client.search({ ...searchCommand, includeMarkings: true });
    }

    return useQuery(
        ['supplyLines', searchCommand, queryOptions?.view] as QueryKey,
        () => authenticatedRequest(queryFn),
        {
            ...options,
            onError: error => {
                handleApiGetError(error);
            },
            enabled: !queryOptions?.disabled,
            cacheTime: 0,
            staleTime: Infinity,
        },
    );
}
