import { SnackbarManager } from '@floriday/floriday-ui';
import * as Sentry from '@sentry/react';

import config from '@config';
import { IGenericError, isGenericError } from '@contracts/IGenericError';
import { ISwaggerError, isSwaggerErrorType } from '@contracts/ISwaggerError';
import { hasTokenExpired, oktaAuthClient } from '@features/Auth/auth';
import i18n, { namespaces } from '@root/i18n';

interface IErrorMessage {
    message: string;
    isUnexpectedError: boolean;
    // API errors usually are already logged on the back-end, so we log them as warnings. However, sometimes the errors that
    // are caught, are errors handling data client-side, so we *do* want to log these failures as error in Sentry.
    sentryIssueType?: 'warning' | 'error';
}

export function handleApiGetError(error: unknown) {
    const errorMessage = getErrorMessage(error);

    if (errorMessage.sentryIssueType === 'error') {
        logToSentry('API', 'Error during API call', 'error', error);
    } else {
        logToSentry('API', 'Error during API call', 'warning', errorMessage);
    }

    if (isGenericError(error) && error.status === 401) {
        oktaAuthClient.signInWithRedirect();
        return;
    }

    SnackbarManager.showError(errorMessage.message);
}

function isNetworkError(error: IGenericError) {
    const message = error.message;

    if (!message) {
        return false;
    }

    return (
        message.includes('Failed to fetch') ||
        message.includes('NetworkError') ||
        message.includes('De netwerkverbinding is verbroken') ||
        message.includes('Kan niet ophalen') ||
        message.includes('Time-out van het verzoek') ||
        message.includes('Network request failed')
    );
}

function getErrorMessage(error: unknown): IErrorMessage {
    function unexpectedError() {
        return {
            message: i18n.t(`${namespaces.general}:genericErrors.unexpectedError`),
            isUnexpectedError: true,
            sentryIssueType: 'error',
        } as IErrorMessage;
    }

    if (isSwaggerErrorType(error)) {
        return {
            message: getSwaggerExceptionErrorMessage(error),
            isUnexpectedError: [500, 501, 502, 503, 504, 505].includes(error.status),
        };
    } else if (!isGenericError(error)) {
        return unexpectedError();
    } else if (isNetworkError(error)) {
        return { message: i18n.t(`${namespaces.general}:genericErrors.networkError`), isUnexpectedError: true };
    } else if (error instanceof SyntaxError) {
        return { message: i18n.t(`${namespaces.general}:genericErrors.syntaxError`), isUnexpectedError: true };
    } else if (error.errorMessages?.length) {
        return { message: error.errorMessages.join('. '), isUnexpectedError: false };
    } else if (error.title) {
        return { message: error.title, isUnexpectedError: false };
    } else {
        return unexpectedError();
    }
}

function getMessageFromApiError(error: ISwaggerError) {
    if (error.response) {
        try {
            const errorObject = JSON.parse(error.response) as IGenericError;

            if (errorObject) {
                if (errorObject.message) {
                    return errorObject.message;
                } else if (errorObject.title) {
                    return errorObject.title;
                } else if (errorObject.errorMessages && errorObject.errorMessages.length > 0) {
                    return errorObject.errorMessages.join('. ');
                }
            }
        } catch {
            return error.response;
        }
    }

    return undefined;
}

function getSwaggerExceptionErrorMessage(exception: ISwaggerError): string {
    const serverMessage = getMessageFromApiError(exception);

    switch (exception.status) {
        case 400:
            return serverMessage ?? i18n.t(`${namespaces.general}:genericErrors.badRequest`);
        case 401:
            return i18n.t(`${namespaces.general}:genericErrors.unauthorized`);
        case 403:
            return i18n.t(`${namespaces.general}:genericErrors.forbidden`);
        case 404:
        case 410:
            return i18n.t(`${namespaces.general}:genericErrors.notFound`);
        case 409:
            // Most of the time, these errors do not contain readable feedback, so always show generic message
            return i18n.t(`${namespaces.general}:genericErrors.conflict`);
        case 504:
            return i18n.t(`${namespaces.general}:genericErrors.gatewayTimeOut`);
        case 500:
        case 502:
        case 503:
        default:
            return i18n.t(`${namespaces.general}:genericErrors.serverError`);
    }
}

export async function authenticatedRequest<T>(request: () => Promise<T>) {
    const isAuthenticated = await oktaAuthClient.isAuthenticated();

    if (isAuthenticated) {
        const tokenExpired = await hasTokenExpired();
        if (tokenExpired) {
            await oktaAuthClient.signInWithRedirect();
            return null;
        }
    } else {
        oktaAuthClient.signInWithRedirect();
        return null;
    }
    return request();
}

export function logToSentry(type: string, message: string, severity: Sentry.SeverityLevel, data?: unknown) {
    if (config.sentry.enabled) {
        const safeType = type.toLowerCase().replace(' ', '-');

        Sentry.addBreadcrumb({
            category: `${safeType}-${severity}`,
            level: severity,
            data: data as {
                [key: string]: unknown;
            },
            message: `${type} ${severity} details`,
        });

        Sentry.captureMessage(message, severity);
    }
}
