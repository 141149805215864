import { Dispatch, createContext, useReducer, useContext, ReactNode, useMemo } from 'react';

import { PreSalesActions } from '@store/Actions/preSalesActions';
import { initialState, PreSalesState, preSalesReducer } from '@store/Reducers/preSalesReducer';

interface IPreSalesContext {
    state: PreSalesState;
    dispatch: Dispatch<PreSalesActions>;
}

interface IProps {
    readonly children?: ReactNode;
}

const initialContext: IPreSalesContext = {
    state: initialState,
    dispatch: () => undefined,
};

const PreSalesContext = createContext(initialContext);

export function PreSalesProvider(props: IProps) {
    const { children } = props;
    const [state, dispatch] = useReducer(preSalesReducer, initialState);

    const value = useMemo<IPreSalesContext>(
        () => ({
            state,
            dispatch,
        }),
        [state],
    );

    return <PreSalesContext.Provider value={value}>{children}</PreSalesContext.Provider>;
}

export function usePreSalesContext(): IPreSalesContext {
    return useContext(PreSalesContext);
}
