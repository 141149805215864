import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';

export default function FustIcon(props: SvgIconProps) {
    return (
        <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M14.5801 9.40997L9.62736 9.40997C9.5034 9.40997 9.39816 9.5008 9.38004 9.62343L8.84071 13.2734C8.8184 13.4244 8.9354 13.56 9.08802 13.56H15.1194C15.272 13.56 15.389 13.4244 15.3667 13.2734L14.8274 9.62343C14.8093 9.5008 14.704 9.40997 14.5801 9.40997ZM9.62736 7.90997L14.5801 7.90997C15.4478 7.90997 16.1845 8.54578 16.3113 9.40416L16.8506 13.0542C17.0068 14.111 16.1878 15.06 15.1194 15.06H9.08802C8.01967 15.06 7.20065 14.111 7.35682 13.0542L7.89616 9.40416C8.02299 8.54578 8.75966 7.90997 9.62736 7.90997ZM6.76375 7.11194L17.4492 7.11194L18.5348 16.5038L18.5349 17.5115H17.3168L16.3793 16.574C15.9105 16.1051 15.2746 15.8417 14.6115 15.8417L9.59591 15.8417C8.93287 15.8417 8.29698 16.1051 7.82814 16.574L6.89065 17.5115H5.67242L5.67237 16.5041L6.76375 7.11194ZM4.17236 16.4173L5.32517 6.49652C5.38376 5.99228 5.81085 5.61194 6.31848 5.61194H17.8947C18.4026 5.61194 18.8298 5.99261 18.8881 6.49711L20.0348 16.4173L20.0349 18.0114C20.0349 18.5637 19.5872 19.0115 19.0349 19.0115H17.1097C16.8445 19.0115 16.5901 18.9061 16.4026 18.7186L15.3186 17.6346C15.1311 17.4471 14.8768 17.3417 14.6115 17.3417L9.59591 17.3417C9.33069 17.3417 9.07634 17.4471 8.8888 17.6346L7.80486 18.7186C7.61732 18.9061 7.36297 19.0115 7.09775 19.0115H5.17245C4.62019 19.0115 4.17248 18.5638 4.17245 18.0115L4.17236 16.4173Z'
                fill='black'
            />
        </SvgIcon>
    );
}
