import IQueueMarking from '@contracts/IQueueMarking';

type AddMarkingToQueue = {
    type: 'ADD_MARKING_TO_QUEUE';
    marking: IQueueMarking;
};

type UpdateMarkingInQueue = {
    type: 'UPDATE_MARKING_IN_QUEUE';
    marking: IQueueMarking;
};

type ResetQueue = {
    type: 'RESET_QUEUE';
};

export type MarkingQueueActions = AddMarkingToQueue | UpdateMarkingInQueue | ResetQueue;

export function addMarkingToQueue(marking: IQueueMarking): AddMarkingToQueue {
    return {
        type: 'ADD_MARKING_TO_QUEUE',
        marking,
    };
}

export function updateMarkingInQueue(marking: IQueueMarking): UpdateMarkingInQueue {
    return {
        type: 'UPDATE_MARKING_IN_QUEUE',
        marking,
    };
}

export function resetQueue(): ResetQueue {
    return {
        type: 'RESET_QUEUE',
    };
}
