import { useEffect, useState } from 'react';
import { NumberInput } from '@floriday/floriday-ui';
import { InputAdornment, Typography } from '@mui/material';
import {
    RangeFilterItem,
    SupplyRangeFilterType,
} from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';

import { getSupplyRangeFilterTypeUnit } from '@utils/SupplyFilterTypeUtils';
import { useStyles } from './RangeFilterStyles';

interface IProps {
    readonly filterType: SupplyRangeFilterType;
    readonly rangeFilter?: RangeFilterItem;
    readonly rangeFilterChanged: (supplyRangeFilterType: SupplyRangeFilterType, min?: number, max?: number) => void;
}

export default function RangeFilter(props: IProps) {
    const { filterType, rangeFilter, rangeFilterChanged } = props;
    const { classes } = useStyles();
    const [min, setMin] = useState(rangeFilter?.min ?? 0);
    const [max, setMax] = useState(rangeFilter?.max ?? 0);
    const unit = getSupplyRangeFilterTypeUnit[filterType];

    useEffect(() => {
        setMin(rangeFilter?.min ?? 0);
        setMax(rangeFilter?.max ?? 0);
    }, [rangeFilter]);

    return (
        <div className={classes.rangeContainer}>
            <NumberInput
                value={min}
                onChange={value => {
                    setMin(value);
                    rangeFilterChanged(filterType, value, max);
                }}
                InputProps={{ endAdornment: <InputAdornment position='end'>{unit}</InputAdornment> }}
                clearZero
                acceptDecimals
                maxNumberOfDecimals={1}
            />
            <Typography className={classes.dash}>-</Typography>
            <NumberInput
                value={max}
                onChange={value => {
                    setMax(value);
                    rangeFilterChanged(filterType, min, value);
                }}
                InputProps={{ endAdornment: <InputAdornment position='end'>{unit}</InputAdornment> }}
                clearZero
                acceptDecimals
                maxNumberOfDecimals={1}
            />
        </div>
    );
}
