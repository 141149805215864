import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    chip: {
        maxWidth: '100%',
    },
    list: {
        maxHeight: '100%',
        overflowY: 'auto',
        width: '100%',
    },
    listMobile: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    listItemMobile: {
        padding: theme.spacing(0.5),
        paddingLeft: 0,
        width: 'auto',
    },
    noOptions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },
    divider: {
        height: '100%',
    },
}));
