import { ArrowBackwardIcon } from '@floriday/floriday-icons';
import { Link as MuiLink } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { namespaces } from '@root/i18n';
import { useStyles } from './PageHeaderStyles';

interface IProps {
    readonly title: string;
    readonly backLink?: string;
    /** If specified, replaces the default Link with an element that will invoke this function when clicked. */
    readonly onBackClick?: () => void;
}

/**
 * Renders a page header on screens sized xl or larger. For detail/editor pages, a link can be provided
 * to navigate back. In these cases, the header is also rendered on lg and smaller.
 *
 * Copy of floriday component to suit our needs and styling
 */
export default function PageHeader(props: IProps) {
    const { backLink, title, onBackClick } = props;
    const { classes } = useStyles();
    const { t } = useTranslation(namespaces.general, { keyPrefix: 'buttons' });

    return (
        <header className={classes.container} data-testid='backButton'>
            {backLink && !onBackClick && (
                <Link className={classes.backLinkContainer} to={backLink}>
                    <ArrowBackwardIcon />
                    <span className={classes.backLinkText}>{t('back')}</span>
                </Link>
            )}

            {onBackClick && (
                <MuiLink className={classes.backLinkContainer} onClick={onBackClick} tabIndex={0} underline='none'>
                    <ArrowBackwardIcon />
                    <span className={classes.backLinkText}>{t('back')}</span>
                </MuiLink>
            )}

            <h1 className={classes.title}>{title}</h1>
        </header>
    );
}
