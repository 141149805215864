import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    buyButtonContainer: {
        textAlign: 'end',
    },
    buyButton: {
        width: '40px',
        height: '40px',
        border: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[50],
    },
    characterIndex: {
        fontWeight: 900,
    },
    row: {
        alignItems: 'center',
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        '&:first-of-type': {
            marginTop: theme.spacing(1.5),
        },
        '&:last-child': {
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
        },
    },
    productDetails: {
        alignItems: 'center',
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
    },
    productName: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    unavailableText: {
        fontStyle: 'italic',
    },
    productQuantity: {
        display: 'flex',
        alignItems: 'center',
    },
}));
