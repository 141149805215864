import { OktaAuth, JWTObject } from '@okta/okta-auth-js';

import { logToSentry } from '@api/helper';
import config from '@config';
import { IUser } from './Contracts/IUser';

export const oktaAuthClient = new OktaAuth({
    clientId: config.okta.auctionPreparationAppClientId,
    issuer: config.okta.issuer,
    redirectUri: config.okta.redirectUri,
    postLogoutRedirectUri: window.location.origin,
    scopes: config.okta.scopes,
    pkce: true,
    tokenManager: {
        autoRemove: false,
        autoRenew: true,
    },
    storageManager: {
        token: {
            storageTypes: ['sessionStorage'],
        },
        cache: {
            storageTypes: ['sessionStorage'],
        },
        transaction: {
            storageTypes: ['sessionStorage'],
        },
    },
});

// Triggered when a token has expired
oktaAuthClient.tokenManager.on('expired', (key: string) => {
    (async () => {
        try {
            const sessionExists = await oktaAuthClient.session.exists();

            if (!sessionExists) {
                await oktaAuthClient.signInWithRedirect();
                return;
            }

            await oktaAuthClient.session.refresh();
            await oktaAuthClient.tokenManager.renew(key);
        } catch (_) {
            oktaAuthClient.signInWithRedirect();
        }
    })();
});

// Triggered when an OAuthError is returned via the API (typically during token renew)
oktaAuthClient.tokenManager.on('error', error => {
    logToSentry('Okta', 'Error during token handling', 'error', error);
    oktaAuthClient.signInWithRedirect();
});

export async function getAccessToken() {
    const tokens = await oktaAuthClient.tokenManager.getTokens();
    return tokens.accessToken?.accessToken;
}

export async function getIdToken() {
    const tokens = await oktaAuthClient.tokenManager.getTokens();
    return tokens.idToken?.idToken;
}

export async function getAndDecodeAccessToken(): Promise<JWTObject | void> {
    const accessToken = await getAccessToken();
    if (accessToken) return oktaAuthClient.token.decode(accessToken);
}

export async function getAndDecodeIdToken(): Promise<JWTObject | void> {
    const idToken = await getIdToken();
    if (idToken) return oktaAuthClient.token.decode(idToken);
}

export async function getUserAccount(): Promise<IUser> {
    const accessToken = (await getAndDecodeAccessToken()) as JWTObject;
    const idToken = (await getAndDecodeIdToken()) as JWTObject;

    return {
        id: (accessToken.payload?.uid as string) ?? idToken.payload?.sub ?? '',
        name: idToken.payload?.name ?? idToken.payload?.preferred_username ?? '',
        email: accessToken.payload?.sub ?? '',
        photoUrl: (idToken.payload?.photoUrl as string) ?? '',
        isAuctioneer: (accessToken.payload?.isAuctioneer as boolean) ?? false,
    };
}

export async function hasTokenExpired(): Promise<boolean> {
    const tokens = await oktaAuthClient.tokenManager.getTokens();

    // If there is no token, it has expired
    if (!tokens.accessToken || !tokens.idToken) {
        return true;
    }

    const isAccessTokenExpired = oktaAuthClient.tokenManager.hasExpired(tokens.accessToken);
    const isIdTokenExpired = oktaAuthClient.tokenManager.hasExpired(tokens.idToken);

    return isAccessTokenExpired || isIdTokenExpired;
}

export async function signOut() {
    await oktaAuthClient.stop();
    oktaAuthClient.signOut({ clearTokensBeforeRedirect: true });
}
