import { FloridayThemeProvider, GlobalStyleProvider } from '@floriday/floriday-ui';
import { purple, green } from '@floriday/floriday-ui/es/Styles/colors';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';

import i18n from './i18n';
import Routes from './routes';
import { initializeSentry } from './sentry';

// eslint-disable-next-line no-console
console.log('Current version Digital Clock Supply Auction Preparation: ' + process.env.VERSION);

// Used for FloridayProviders
// For DigitalClockSupply the provider is injects since it's a 'child' of Floriday
// This is needed for running the app as standalone
// Check src\theme.ts in customer-portal repo for the right colors
const moduleTheme = { primary: purple, secondary: green };
const defaultProps = {
    components: {
        MuiPopover: {
            defaultProps: {
                disableAutoFocus: true,
                disableEnforceFocus: true,
                // Prevents scroll position jumping: https://github.com/mui-org/material-ui/issues/10756
                disableRestoreFocus: true,
            },
        },
    },
};

initializeSentry();

const app = (
    <I18nextProvider i18n={i18n}>
        <FloridayThemeProvider {...moduleTheme} themeOptions={defaultProps}>
            <GlobalStyleProvider>
                <Routes />
            </GlobalStyleProvider>
        </FloridayThemeProvider>
    </I18nextProvider>
);

const root = createRoot(document.getElementById('root') as Element);
root.render(app);
