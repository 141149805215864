import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    buttonGroup: {
        [theme.breakpoints.down('sm')]: {
            height: '40px',
            width: '80px',
        },
        height: '24px',
        borderRadius: theme.spacing(0.5),
        border: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[50],
        '.MuiButtonBase-root.MuiButtonGroup-lastButton': {
            height: '24px',
            maxHeight: '24px',
            minHeight: 0,
        },
        verticalAlign: 'middle',
    },
    squareIconButton: {
        borderRadius: `0 ${theme.spacing(0.5)} ${theme.spacing(0.5)} 0`,
        borderLeft: `1px solid ${theme.palette.grey[300]}`,
        [theme.breakpoints.down('sm')]: {
            height: '40px',
            width: '40px',
        },
    },
    arrowIcons: {
        width: '12px',
    },
    popper: {
        zIndex: 9999,
    },
    paperHeader: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        fontWeight: 'bold',
        padding: theme.spacing(2),
    },
    closeButton: {
        float: 'right',
    },
    closeIcon: {
        color: theme.palette.grey[900],
        fontSize: '14px',
    },
    dialogTitle: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    dialogContent: {
        padding: theme.spacing(0, 1),
    },
}));
